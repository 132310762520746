import React from 'react'

const Success = (props) => {
  const payment = () => {
    if (props.type === 'creditcard') {
      return (
        <React.Fragment>
          <p className="notif">YOUR CARD WAS SAVED</p>
          <p>
            Please close this tab to return to the platform.
          </p>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <p className="notif">YOUR PAYMENT WAS APPROVED</p>
          <p>
            Thank you for your payment. You will receive a confirmation email for your records shortly.
          </p>
        </React.Fragment>
      )
    }
  }

  const calendar = () => {
    return (
      <React.Fragment>
        <p className="notif">CALENDAR SUBMITTED</p>
        <p>Thank you for providing potential inspection times. Please note, these selections do not confirm an inspection time. Once your selected times have been reviewed by your assigned appraiser, you will receive a confirmation email with the final inspection time and date.</p>
      </React.Fragment>
    )
  }


  let nextButtonText
  if (props.nextStepKey === 'payment') {
    nextButtonText = 'Make Payment'
  } else if (props.nextStepKey === 'files') {
    nextButtonText = 'Upload P&S and Other Documents'
  }
  const nextButton = nextButtonText
    ? (
      <div className="button" onClick={() => props.changeStep()}>
          {nextButtonText}
      </div>
      )
    : null
  return (
    <div className="flex-container small text-center">
      <div className="box">
        <div className="success">
          <div className="icon icon-cell animate">done</div>
        </div>
        <h1>Success</h1>
        {props.type === 'calendar' ? calendar() : payment()}
        {nextButton}
      </div>
    </div>
  )
}

export default Success
