import React, { useCallback } from 'react'
import useAnalyticsContext from 'helpers/analytics-context/useAnalyticsContext'
import { AnalyticsProvider } from 'helpers/analytics-context'


const useAnalytics = () => {
  const { analytics } = useAnalyticsContext()

  const track = useCallback(
    (event, payload) => {
      analytics?.track(event, payload)
    },
    [analytics]
  )

  const identify = useCallback(
    (id, user) => {
      analytics?.identify(id, user)
    },
    [analytics]
  )

  return {
    track,
    identify,
  }
}

const WithAnalytics = (WrappedComponent) => {
  const AnalyticsWrapper = (props) => {
    const analytics = useAnalytics()
    const allProps = { ...props, analytics }
    return (
      <AnalyticsProvider>
        <WrappedComponent {...allProps} />
      </AnalyticsProvider>
    )
  }
  return AnalyticsWrapper
}

export default WithAnalytics
