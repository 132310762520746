import React from 'react'

import Modal from 'components/Modal'


const ConfirmationModal = (props) => (
  <Modal onClose={props.onClose}>
    {props.children}
    <div className="button no-transform" onClick={props.onClose}>
      { props.closeText || 'Back'}
    </div>
  </Modal>
)


const AlertModal = (props) => (
  <Modal onClose={props.onClose}>
    {props.children}
    <div className="button secondary no-transform" onClick={props.onClose}>
      { props.closeText || 'Back'}
    </div>
    <div className="button no-transform" onClick={props.onSubmit}>
      { props.submitText || 'Submit'}
    </div>
  </Modal>
)


export {
  ConfirmationModal,
  AlertModal,
}
