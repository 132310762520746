import { handleActions } from 'redux-actions'

import { fileActions } from '../types'

const initialState = {
  documents: [],
}

export default handleActions(
  {
    [fileActions.FILE_ADD]: (state, action) => {
      const newState = state
      state.documents.push(action.payload)
      return {
        ...newState,
      }
    },
    [fileActions.INITIALIZE_DOCUMENTS]: (state, action) => {
      return {
        ...state,
        documents: action.payload,
      }
    },
  },
  initialState,
)
