import {
  createAction,
} from 'redux-actions'
import {
  apiRequest,
} from '../../helpers/Request'
import * as appActions from './app'
import {
  propertyDetailActions,
} from '../types'

export const setDetails = createAction(propertyDetailActions.SET_DETAILS)
export const setDetailValue = createAction(propertyDetailActions.SET_DETAIL_VALUE)

export const loadPropertyDetails = token => async dispatch => {
  try {
    const { status, data: responseData } = await apiRequest({
      endpoint: `/property-details/${token}`,
      method: 'get',
    })
    if (status === 200) {
      dispatch(setDetails(responseData))
    } else {
      const { errors } = responseData
      dispatch(appActions.appLoading(false))
      dispatch(appActions.appErrors(errors))
    }
  } catch (err) {
    const errors = err.response && err.response.data && err.response.data.errors ? err.response.data.errors : ['Unable to retrieve property detail information.']
    dispatch(appActions.appLoading(false))
    dispatch(appActions.appErrors(errors))
  }
}

export const persistDetails = ({ details, token }) => async dispatch => {
  try {
    dispatch(appActions.appLoading(true))
    const { status, data: responseData } = await apiRequest({
      endpoint: `/property-details/${token}`,
      method: 'put',
      data: details,
    })
    if (status === 200) {
      dispatch(setDetails(responseData))
      dispatch(appActions.appLoading(false))
    } else {
      const { errors } = responseData
      dispatch(appActions.appLoading(false))
      dispatch(appActions.appErrors(errors))
    }
  } catch (err) {
    const errors = err.response && err.response.data && err.response.data.errors ? err.response.data.errors : ['Unable to persist property detail information.']
    dispatch(appActions.appLoading(false))
    dispatch(appActions.appErrors(errors))
  }
}
