import React from 'react';

class Input extends React.Component {
  ENTER_KEYCODE = 13;

  renderErrors() {
    const errors = [];
    for (let error of this.props.errors) {
      errors.push(<li key={error}>{error}</li>);
    }
    return <ul className="error-list">{errors}</ul>;
  }

  onChange = e => {
    this.props.onChange(e.target.value, this.props.field);
  };

  onKeyUp = e => {
    if (e.keyCode === this.ENTER_KEYCODE) {
      this.props.onSubmit && this.props.onSubmit();
    }
  };

  renderInput() {
    const input = (
      <input
        onChange={this.onChange}
        style={this.props.style}
        className={this.props.className}
        value={this.props.value}
        onKeyUp={this.onKeyUp}
        {...this.props.input}
      />
    );
    if (this.props.icon) {
      return (
        <div className="input-icon">
          {input}
          {this.props.errors && this.renderErrors()}
          <span className="icon">{this.props.icon}</span>
        </div>
      );
    }
    return (
      <React.Fragment>
        {input}
        {this.props.errors && this.renderErrors()}
      </React.Fragment>
    );
  }
  render() {
    return <React.Fragment>{this.renderInput()}</React.Fragment>;
  }
}

export default Input;
