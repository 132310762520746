import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useViewport } from '../hooks'
import { appModal } from '../store/actions/app'
import {
  selectAppStep,
  selectAppIframe,
  selectAppLoading,
  selectAppModal,
} from '../store/selectors/app'
import {
  selectUserLender,
  selectUserPermissions,
} from '../store/selectors/user'
import { selectOrderLenderLogo, selectOrderBranchLogo } from '../store/selectors/order'

import ReggoraLogo from '../static/img/ReggoraIconLogo.png'
import '../static/style/style.css'

import Loading from '../components/Loading'
import { ConfirmationModal } from '../components/ModalTypes'
import MobileStepper from '../components/MobileStepper'
import DesktopStepper from '../components/DesktopStepper'

import Notifications from './Notifications'
import { CalendarError, PwResetModal } from './modals'

const MODALS = {
  password_reset: <PwResetModal />,
  calendar_error: <CalendarError />,
}

const Navigation = () => {
  const dispatch = useDispatch()
  const viewport = useViewport()
  const step = useSelector(selectAppStep)
  const iframe = useSelector(selectAppIframe)
  const loading = useSelector(selectAppLoading)
  const modal = useSelector(selectAppModal)
  const lender = useSelector(selectUserLender)
  const permissions = useSelector(selectUserPermissions)
  const lenderLogo = useSelector(selectOrderLenderLogo)
  const branchLogo = useSelector(selectOrderBranchLogo)

  const steps = [
    'Welcome',
    ...(permissions && permissions.includes('payment') ? ['Payment'] : []),
    ...(permissions && permissions.includes('schedule') ? ['Schedule', 'Property Details'] : []),
    'Done',
  ]

  const onConfirmationModalClose = () => dispatch(appModal(''))

  return (
    <React.Fragment>
      {loading && <Loading />}
      {modal && (
        <ConfirmationModal onClose={onConfirmationModalClose}>
          {MODALS[modal]}
        </ConfirmationModal>
      )}
      <Notifications />
      {!iframe && !lender && (
        <div className="nav-bar">
          <img
            src={branchLogo || lenderLogo || ReggoraLogo}
            className="logo"
            alt="Reggora"
          />
          {viewport.isMediumScreenOrBelow
            ? (
            <MobileStepper steps={steps} step={step} />
              )
            : (
            <DesktopStepper steps={steps} step={step} />
              )}

        </div>
      )}
    </React.Fragment>
  )
}

export default Navigation
