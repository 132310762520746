import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as appActions from 'store/actions/app';
import * as userActions from 'store/actions/user';

import Input from 'components/Input';
import Validator from 'helpers/Validator';

class Login extends Component {
  state = {
    password: '',
    errors: {},
  };

  onFieldUpdate = (value, field) => {
    this.setState({ [field]: value });
  };

  submitLogin = () => {
    const { password } = this.state;
    const { appValidate } = this.props.appActions;
    const { userLogin } = this.props.userActions;
    const { _id } = this.props.user;
    const validation = {
      password: [Validator.required(password), Validator.checkLength(password, 8)],
    };

    const data = {
      password,
      user_id: _id,
    };
    appValidate(validation, userLogin, data);
  };

  submitPasswordReset = () => {
    const { _id } = this.props.user;
    this.props.userActions.userRequestPasswordReset({ user: _id });
  };

  render() {
    const { formErrors } = this.props.app;
    return (
      <div className="container small">
        <div className="center-all">
          <div className="box bg">
            <h1>Login</h1>
            <p className="info">Welcome back {this.props.user.name}, please login to proceed</p>
            <Input
              input={{ type: 'password', placeholder: 'Enter password' }}
              onChange={this.onFieldUpdate}
              onSubmit={this.submitLogin}
              field="password"
              value={this.state.password}
              errors={formErrors.password}
              icon="lock_outline"
            />
            <div className="button secondary" onClick={this.submitPasswordReset}>
              Reset Password
            </div>
            <div className="button" onClick={this.submitLogin}>
              Login
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, user }) => ({ app, user });
const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(appActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
