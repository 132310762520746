import React, { Fragment } from 'react'

const scheduleText = 'would like you to set your available appraisal inspection times.'
const bothText = 'would like you to provide additional information prior to the home appraisal.'

const getPayText = (borrower_payment_text) => {
  return `would like you to complete the payment for your ${borrower_payment_text}.`
}

const introText = (permissions, borrower_payment_text) => {
  switch (permissions) {
    case 'schedule':
      return scheduleText
    case 'payment':
      return getPayText(borrower_payment_text)
    default:
      return bothText
  }
}

const borrowerStillOwesMoney = (balance) => {
  if (balance > 0) {
    return true
  }
}

const getActivePayment = (props) => {
  const { schedule: { payments }, user } = props

  const activePayment = payments.find((p) => {
    return (
      !p.paid && !p.card_information_captured &&
      (p.consumer == user.id || p.payment_type == 'lender') &&
      p.payment_type !== 'manual'
    )
  })
  return activePayment
}

const canMakePayment = (props) => {
  if (borrowerStillOwesMoney(props.balance) && getActivePayment(props)) {
    return true
  }
  return false
}

const Intro = ({
  name, 
  permissions, 
  branchName, 
  lenderName,
  borrower_payment_text,
  additionalText,
  changeStep,
}) => {
  const lenderDisplayName = branchName || lenderName
  return (
  <div className='flex-container text-center'>
    <div className="box">
      <h1>Hi {name}</h1>
      { permissions
        ? <Fragment>
        <p className="info">
          {lenderDisplayName} {introText(permissions, borrower_payment_text)}
        </p>
        <p>Let's get started</p>
        {additionalText}
        {changeStep && (
          <div className="button" onClick={() => changeStep()}>
            Continue
          </div>
        )} </Fragment>
        : <p className='info'>
        It looks like {lenderDisplayName} requested information from you in the past, but it may no longer be necessary. This link is now invalid. If you think this is a mistake, please contact {lenderDisplayName}.
        </p>
      }
    </div>
  </div>
  )
}

export default Intro
