import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { configureStore } from './store'

import './index.css'
import 'antd/dist/antd.css'
import App from './App'

import unregister from './registerServiceWorker'

const appContainer = document.getElementById('root')
const store = configureStore()

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    appContainer
)
unregister()
