import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as appActions from 'store/actions/app';

class Notifications extends React.Component {
  state = {
    messages: {},
    msg_id: 0,
    successID: 0,
  };

  componentWillReceiveProps(nextProps) {
    const { messages, msg_id, successID } = this.state;
    const { errors, successes } = nextProps.app;
    if (errors && errors.length) {
      for (let i = 0; i < errors.length; i++) {
        const msgID = msg_id + i;
        const error = errors[i];
        /*
          In the future, this should be component
          It will receive object like { info_level: 'Message text'}
          As such, it would display differet icons and classes for colors
        */
        messages[msgID] = (
          <div className="notification" key={msgID}>
            <div className="icon">error_outline</div>
            <div className="text">{error}</div>
            <div className="icon close" onClick={() => this.removeMsg(msgID)}>
              close
            </div>
          </div>
        );
        setTimeout(() => {
          delete messages[msgID];
          this.setState({ messages });
        }, 5000);
      }

      this.setState({
        messages,
        msg_id,
      });
      this.props.appActions.appErrors([]);
    }
    if (successes && successes.length) {
      for (let i = 0; i < successes.length; i++) {
        const msgID = successID + i;
        const newSuccessID = 'success' + msgID;
        const success = successes[i];
        messages[newSuccessID] = (
          <div className="notification success-notification" key={newSuccessID}>
            <div className='icon green'>check_circle_outline</div>
            <div className='text'>{success}</div>
            <div className='icon close' onClick={() => this.removeMsg(newSuccessID)}>close</div>
          </div>
        );
        setTimeout(() => {
          this.setState(prevState => {
            const allMessages = {...prevState.messages};
            delete allMessages[newSuccessID];
            this.setState({messages: allMessages});
          });
        }, 5000);
      }
      this.props.appActions.appSuccesses([]);
    }
  }

  removeMsg = msgID => {
    const { messages } = this.state;
    delete messages[msgID];
    this.setState({ messages });
  };

  renderErrors() {
    const { messages } = this.state;
    return Object.keys(messages).map(k => messages[k]);
  }

  render() {
    return <div className="notifications">{this.renderErrors()}</div>;
  }
}

const mapStateToProps = ({ app }) => ({ app });
const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(appActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
