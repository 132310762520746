import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as appActions from 'store/actions/app';
import * as userActions from 'store/actions/user';

import Input from 'components/Input';
import Validator from 'helpers/Validator';

class Register extends Component {
  state = {
    password: '',
    password_confirm: '',
  };

  onFieldUpdate = (value, field) => {
    this.setState({ [field]: value });
  };

  submitRegister = () => {
    const { password, password_confirm } = this.state;
    const { appValidate } = this.props.appActions;
    const { userRegister } = this.props.userActions;
    const { _id } = this.props.user;
    const validation = {
      password: [Validator.required(password), Validator.checkLength(password, 8)],
      password_confirm: [
        Validator.required(password_confirm),
        Validator.checkLength(password_confirm, 8),
        Validator.sameInput(password, password_confirm),
      ],
    };

    const data = {
      password,
      user_id: _id,
    };

    appValidate(validation, userRegister, data);
  };

  render() {
    const { formErrors } = this.props.app;
    return (
      <div className="container small">
        <div className="center-all">
          <div className="box bg">
            <h1>Register</h1>
            <p className="info">
              Hi {this.props.user.name}, seems like you are first time visiting <br />
              To proceed, please register your account
            </p>
            <Input
              input={{ type: 'password', placeholder: 'Enter password' }}
              onChange={this.onFieldUpdate}
              field="password"
              value={this.state.password}
              errors={formErrors.password}
              icon="lock_outline"
            />
            <Input
              input={{ type: 'password', placeholder: 'Confirm password' }}
              onChange={this.onFieldUpdate}
              onSubmit={this.submitRegister}
              field="password_confirm"
              value={this.state.password_confirm}
              errors={formErrors.password_confirm}
              icon="lock_outline"
            />
            <div className="button" onClick={this.submitRegister}>
              Register
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, user }) => ({ app, user });
const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(appActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
