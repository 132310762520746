import React from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import moment from 'moment';

import { ConfirmationModal } from 'components/ModalTypes';
import Calendar from 'components/Calendar';

class CalendarPreview extends React.Component {
  processDates = (available_dates) => {
    const processed_available_dates = [];
    available_dates.forEach(dateRange => {
      const from = moment.utc(dateRange.beginning);
      const to = moment.utc(dateRange.end);
      from.local()
      to.local()
      processed_available_dates.push({ from, to });
    });
    return processed_available_dates;
  }

  processConsumers = (schedule) => {
    const {
      consumers,
    } = schedule;
    if (!consumers || consumers.length === 0) {
      return [];
    }
    const allConsumers = [];
    schedule.consumer_dates.forEach(consumer_date => {
      let c = consumers.find(cons => cons.id === consumer_date.consumer)
      allConsumers.push({
        name: c.name,
        email: c.email,
        available_dates: this.processDates(consumer_date.available_dates),
      });
    });
    return allConsumers;
  }

  state = {
    modal: false,
    day: null,
    dates: this.processDates(this.props.schedule.consumer_dates.find(c => c.consumer === this.props.user.id).available_dates),
    processedConsumers: this.processConsumers(this.props.schedule),
  };

  componentWillReceiveProps = (nextProps) => {
    const {
      user,
      schedule,
    } = this.props;
    if (user !== nextProps.user) {
      this.setState({dates: this.processDates(nextProps.user)});
    }
    if (schedule !== nextProps.schedule) {
      this.setState({processConsumers: this.processConsumers(nextProps.schedule)});
    }
  }

  renderDates() {
    //const { available_dates } = this.props.user;
    const {
      dates,
    } = this.state;
    return dates.map((date, i) => (
      <span key={i}>
        {dateFns.format(date.from, 'DD/MM/YYYY HH:ss a')} -
        {dateFns.format(date.to, 'DD/MM/YYYY HH:ss a')}
      </span>
    ));
  }

  showAvailableDates = day => {
    this.setState({
      day,
      modal: true,
    });
  };

  renderModal() {
    //const { available_dates } = this.props.user;
    const {
      //dates,
      processedConsumers,
      day,
    } = this.state;
    const DATE_FORMAT = 'hh:ss a';
    const times = [];
    processedConsumers.forEach(c => {
      const consumerTimes = [];
      c.available_dates.filter(d => dateFns.isSameDay(day, d.from)).forEach((d, idx) => {
        consumerTimes.push(<p key={idx}>
          {dateFns.format(d.from, DATE_FORMAT)} - {dateFns.format(d.to, DATE_FORMAT)}
        </p>)
      });
      if (consumerTimes.length > 0) {
        times.push(<div>
          <h3>{c.name} ({c.email})</h3>
          {consumerTimes}
        </div>);
      }
    });
    return (
      <ConfirmationModal onClose={() => this.setState({ modal: false })}>
        <div style={{ marginBottom: '30px' }}>
          <h1>Available Times for {moment(day).format('MMMM Do')}</h1>
          <br />
          {times}
        </div>
      </ConfirmationModal>
    );
  }

  render() {
    const {
        goToEdit,
    } = this.props;
    const {
      processedConsumers,
    } = this.state;
    return (
      <React.Fragment>
        {this.state.modal && this.renderModal()}
        <div className="container top">
          <div className="box">
            <h1>Calendar</h1>
            <p className="intro">Preview the available times you have selected for this appraisal</p>
            <br />
            <Calendar consumers={processedConsumers} onClick={this.showAvailableDates} />
            {goToEdit ? <div className='button' onClick={goToEdit}>Edit Dates</div> : null}
            {this.props.changeStep && (
              <div className="button" onClick={() => this.props.changeStep()}>
                Continue
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ app, schedule, user }) => ({ app, schedule, user });
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPreview);
