import { createAction } from 'redux-actions'

import Validator from 'helpers/Validator'
import { appActions } from '../types'

export const appLoading = createAction(appActions.APP_LOADING)
export const appComponent = createAction(appActions.APP_COMPONENT)
export const appModal = createAction(appActions.APP_MODAL)
export const appErrors = createAction(appActions.APP_ERRORS)
export const appSuccesses = createAction(appActions.APP_SUCCESSES)
export const appFormErrors = createAction(appActions.APP_FORM_ERRORS)
export const appStep = createAction(appActions.APP_STEP)
export const appStepIncrement = createAction(appActions.APP_STEP_INCREMENT)
export const appIframe = createAction(appActions.APP_IFRAME)
export const appSetToken = createAction(appActions.SET_TOKEN)

export function appValidate(data, action, params) {
  return (dispatch) => {
    const [hasErrors, errors] = Validator.validateFromObj(data)
    if (hasErrors) {
      dispatch(appFormErrors(errors))
    } else {
      dispatch(appFormErrors({}))
      if (action) {
        action(params)
      }
    }
  }
}


export const displayError = error => dispatch => dispatch(appErrors(error))
