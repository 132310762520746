const getLDUser = function(userID, lenderName) {
  return {
    key: userID,
    lender: lenderName,
    custom: {
      type: 'LENDER',
    },
  }
}

export { getLDUser }
