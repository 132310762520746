import { handleActions } from 'redux-actions'

import { creditCardActions } from '../types'

const initialState = {
  id: null,
  cc_type: null,
}

export default handleActions(
  {
    [creditCardActions.CREDITCARD_SET]: (state, action) => {
      return ({
        ...state,
        cc_type: action.payload.cc_type,
        id: action.payload.id,
      })
    },
    [creditCardActions.CREDITCARD_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState,
)
