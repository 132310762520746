import { createAction } from 'redux-actions'
import { push } from 'react-router-redux'

import { apiRequest } from 'helpers/Request'
import Routes from 'routes'
import * as appActions from './app'
import { userActions } from '../types'
import processError from '../../helpers/processError'

export const isLender = createAction(userActions.IS_LENDER)
export const userSet = createAction(userActions.USER_SET)
export const userUpdate = createAction(userActions.USER_UPDATE)
export const userLogout = createAction(userActions.USER_LOGOUT)

export function userGet(user_id) {
  return (dispatch) => {
    apiRequest({
      endpoint: '/user',
      method: 'get',
      data: {
        params: {
          user_id,
        },
      },
    })
      .then((response) => {
        dispatch(userSet(response))
        if (response.data.registered) {
          dispatch(appActions.appComponent('login'))
        } else {
          dispatch(appActions.appComponent('register'))
        }
      })
      .catch((error) => {
        dispatch(push(Routes.error))
      })
    dispatch(appActions.appLoading(false))
  }
}

export function userLogin(data) {
  return (dispatch) => {
    apiRequest({
      endpoint: '/login',
      method: 'post',
      data,
    })
      .then((response) => {
        dispatch(appActions.appComponent('schedule'))
      })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
      })
  }
}

export function userRegister(data) {
  return (dispatch) => {
    apiRequest({
      endpoint: '/register',
      method: 'post',
      data,
    })
      .then((response) => {
        dispatch(appActions.appComponent('schedule'))
      })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
      })
  }
}

export function userRequestPasswordReset(data) {
  return (dispatch) => {
    dispatch(appActions.appLoading(true))
    apiRequest({
      endpoint: '/password_reset',
      method: 'post',
      data,
    })
      .then((response) => {
        dispatch(appActions.appModal('password_reset'))
        dispatch(appActions.appLoading(false))
      })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
        dispatch(appActions.appLoading(false))
      })
  }
}

export function userPasswordReset(data) {
  return (dispatch) => {
    apiRequest({
      endpoint: '/password_reset',
      method: 'put',
      data,
    })
      .then((response) => {
        dispatch(push(`/schedule/${response.data.user}`))
      })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
      })
  }
}

export const checkConsumer = (token) => {
  return dispatch => {
    return apiRequest({
      endpoint: '/order/check-consumer',
      method: 'post',
      data: { token },
    })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
      })
  }
}

export const userConsent = (token) => {
  return dispatch => {
    return apiRequest({
      endpoint: '/order/consent',
      method: 'put',
      data: { token },
    })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
      })
  }
}

export const denyConsent = (token) => {
  return dispatch => {
    return apiRequest({
      endpoint: '/order/deny-consent',
      method: 'put',
      data: { token },
    })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
      })
  }
}
