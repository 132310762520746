import React from 'react'
import 'antd/dist/antd.css'
import { Radio, Space } from 'antd'
import { AVAILABILITY_TYPE_OCCUPIED, AVAILABILITY_TYPE_UNOCCUPIED } from '../../helpers/constants'

const ScheduleOptions = ({ value, onChange }) => {
  return (
    <div className='direction-left'>
      <p className="info margin-bottom">
        How is this Property available for appraisers?
      </p>
      <Radio.Group onChange={onChange} value={value} name='availabilityType'>
        <Space direction="vertical">
          <Radio value={AVAILABILITY_TYPE_UNOCCUPIED}>Property is not occupied and has flexible availability</Radio>
          <Radio value={AVAILABILITY_TYPE_OCCUPIED}>
            Property is currently occupied and availability must be scheduled with residents
          </Radio>
        </Space>
      </Radio.Group>
    </div>
  )
}

export default ScheduleOptions
