import React, { useState, useEffect } from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { AnalyticsContext } from './context'

export const AnalyticsProvider = ({
  children,
}) => {
  const WRITE_KEY = process.env.SEGMENT_KEY

  const [analytics, setAnalytics] = useState(undefined)

  const loadAnalytics = async () => {
    if (!WRITE_KEY || analytics) {
      return
    }

    const [response] = await AnalyticsBrowser.load({ writeKey: WRITE_KEY })

    setAnalytics(response)
  }

  useEffect(() => {
    loadAnalytics()
  }, [WRITE_KEY])

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  )
}
