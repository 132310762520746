import React, { Component } from 'react'
import moment from 'moment-timezone'

import CalendarError from './modals/CalendarError'
import { Calendar } from './shared'
import { ScheduleOptions, FlexibleAvailability } from '../components/schedule'
import { AVAILABILITY_TYPE_OCCUPIED, AVAILABILITY_TYPE_UNOCCUPIED } from '../helpers/constants'
import WithAnalytics from 'components/WithAnalytics'


// here we create the dates time and duration we will display to the POC
const FROM_DATE = new Date()
const TO_DATE = new Date()
TO_DATE.setDate(FROM_DATE.getDate() + 14)
FROM_DATE.setDate(FROM_DATE.getDate() + 2)

// time in hours
const FROM_TIME = 9
const TO_TIME = 15
const MINIMUM_DURATION = 3

const MINIMUM_DAYS = 3
const MINIMUM_HOURS = 3

const TIME_FORMAT = 'YYYY-MM-DD-HH-mm'

const currentTimezoneOffset = moment().format('Z')

const usaZones = moment.tz.zonesForCountry('US')
const dynamicZones = usaZones.map(f => {
  const zoneValue = moment().tz(f).format('Z')

  return {
    name: moment.tz.zone(f).name,
    value: zoneValue,
    offset: parseInt(zoneValue.substring(0, zoneValue.indexOf(':'))),
  }
})

const reggoraTimezoneToIanaTimezone = {
  'US/Hawaii': 'Pacific/Honolulu',
  'US/Alaska': 'America/Ancorage',
  'US/Pacific': 'America/Los_Angeles',
  'US/Mountain': 'America/Boise',
  'US/Central': 'America/Chicago',
  'US/Eastern': 'America/New_York',
}

const ianaTimezoneMap = Object.entries(reggoraTimezoneToIanaTimezone)
  .reduce((acc, [key, value]) => ({ ...acc, [key]: dynamicZones.find(f => f.name === value) }), {})

export const getFormAnalytics = (startTimes) => {
  const numberOfSlots = startTimes.length
  const tz = moment().utcOffset()

  const startTimeSelections = startTimes.reduce((acc, d) => {
    const localTime = d.local().utcOffset(tz, true)
    const selectedHour = localTime.hour()
    const selectedDay = localTime.format('dddd')
    if (!acc.hours[selectedHour]) {
      acc.hours[selectedHour] = 0
    }
    acc.hours[selectedHour] += 1
    if (!acc.days[selectedDay]) {
      acc.days[selectedDay] = 0
    }
    acc.days[selectedDay] += 1
    return acc
  }, {
    hours: {},
    days: {},
  })
  console.log(startTimes.map(st => st.format()))
  return {
    formName: 'scheduling_form', // TODO replace this with the order's form type
    startTimeSelections,
    numberOfDays: Object.values(startTimeSelections.days).length,
    numberOfSlots,
  }
}
class ScheduleContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _now: moment({ hour: 0, minute: 0, seconds: 0 }),
      _selected: moment(),
      update: -1,
      startTime: '',
      endTime: '',
      error: '',
      availabilityType: '',
      availabilityNotes: '',
      dates: {},
      isError: false,
      otherConsumerDates: {},
      consumerColors: {},
      timezone: currentTimezoneOffset,
    }
  }

  setAvalabilityType = () => {
    const { userRole, availabilityType } = this.props
    if (userRole === 'borrower') {
      this.setState({ availabilityType: AVAILABILITY_TYPE_OCCUPIED })
      return
    }
    if (availabilityType) {
      this.setState({ availabilityType })
    }
  }

  componentDidMount() {
    const { availabilityNotes } = this.props

    this.setState({
      timezone: this.getPropertyTimezone(),
    }, () => {
      this.setState({
        availabilityNotes,
        dates: this.getCurrentandPreviousAvailableDates(),
        otherConsumerDates: this.getDatesFromOtherConsumers(),
        consumerColors: this.getConsumerColors(),
      })
      this.setAvalabilityType()
    })
  }

  transformBackendTimezoneToIanaTimezone = (source) => {
    return ianaTimezoneMap[source] ?? null
  }

  getPropertyTimezone = () => {
    const propertyTimezone = this.transformBackendTimezoneToIanaTimezone(this.props.order.property_timezone)
    const timezone = propertyTimezone?.value || currentTimezoneOffset

    return timezone
  }

  getCurrentandPreviousAvailableDates = () => {
    const {
      userId,
      consumerDates,
    } = this.props

    const available_dates = consumerDates.find(date => date.consumer === userId)


    let previousDates = {}

    if (available_dates) {
      previousDates = this.processAvailableDates(available_dates.available_dates, userId)
    }

    return previousDates
  }

  processAvailableDates = (dates, email) => {
    const result = {}

    for (const selDateTime of dates) {
      // TODO: Unsure where it makes sense to convert the times, see notes below
      // It may be reasonable to convert the incoming times in UTC to the specified offset like below; but this causes issues with the calendar
      // const momentStart = moment.utc(selDateTime.beginning).utcOffset(this.state.timezone)
      // const momentEnd = moment.utc(selDateTime.end).utcOffset(this.state.timezone)
      const momentStart = moment.utc(selDateTime.beginning)
      const momentEnd = moment.utc(selDateTime.end)
      momentStart.local() // Causes problems if the user comes back with a different timezone than what they set
      momentEnd.local() // Causes problems if the user comes back with a different timezone than what they set

      const startmins = momentStart.format('mm')

      const preDuration = moment.duration(momentEnd.diff(momentStart)).asMinutes()

      result[momentStart.format('YYYY-MM-DD-H')] = [[startmins.toString(), preDuration, momentStart, momentEnd, email]]
    }

    return result
  }

  getDatesFromOtherConsumers = () => {
    const {
      userId,
      consumerDates,
    } = this.props
    const otherDates = {}
    for (const consumerDate of consumerDates) {
      if (consumerDate.consumer !== userId) {
        if (consumerDate.available_dates) {
          const consumerDates = this.processAvailableDates(consumerDate.available_dates, consumerDate.consumer)
          for (const key of Object.keys(consumerDates)) {
            if (key in otherDates) {
              otherDates[key].append(consumerDates[key][0])
            } else {
              otherDates[key] = consumerDates[key]
            }
          }
        }
      }
    }
    return otherDates
  }

  getConsumerColors = () => {
    const {
      userId,
      consumerDates,
    } = this.props
    const colors = [
      'green',
      'orange',
      'purple',
      'yellow',
      // 'blue',
      'red',
    ]
    const colorMap = {}
    let colorIndex = 0
    for (let i = 0; i < consumerDates.length; i++) {
      if (userId === consumerDates[i].id) {
        continue
      }
      while (colorIndex >= colors.length) {
        colorIndex -= colors.length
      }
      colorMap[consumerDates[i].consumer] = colors[colorIndex]
      colorIndex++
    }
    colorMap[userId] = 'blue'
    return colorMap
  }

  onChangeInput = event => {
    const name = event.target?.name
    this.setState({ [name]: event.target?.value })
  }

  isContinueDisable = () => {
    // all dates come in format yyyy-mm-dd-hr, we will slice dates from index 0 to 10 to get dates without the hour
    // we then use yyyy-mm-dd format to get unique dates
    const { dates, availabilityType } = this.state
    if (availabilityType === AVAILABILITY_TYPE_OCCUPIED) {
      const uniqueDates = Object.keys(dates).reduce((accumulator, date) => {
        const dateWithoutHours = date.slice(0, 10)
        if (!accumulator.includes(dateWithoutHours) && dates[date].length !== 0) {
          accumulator.push(dateWithoutHours)
        }
        return accumulator
      }, [])
      let datesCount = 0
      for (const date in dates) {
        if (dates[date] && dates[date].length !== 0) {
          datesCount += 1
        }
      }
      if (datesCount < 3 || uniqueDates.length < 3) {
        return true
      }
    }
  }

  isErrorToggle = () => this.setState({ isError: !this.state.isError })

  formatDates(dates) {
    const formattedDates = []
    for (const date in dates) {
      for (const time of dates[date]) {
        const startTime = time[2]
        const endTime = time[3]
        formattedDates.push({
          beginning: startTime.utc().format(TIME_FORMAT),
          end: endTime.utc().format(TIME_FORMAT),
        })
      }
    }
    return formattedDates
  }


  submitFormAnalytics(selectedDates) {
    this.props.analytics.track('scheduling_continue_button_clicked', getFormAnalytics(selectedDates))
  }

  submitCalendar = () => {
    const {
      actions,
      userId,
      order: { id: order_id },
    } = this.props
    const { availabilityNotes, availabilityType, dates, timezone } = this.state
    if (this.isContinueDisable()) {
      this.isErrorToggle()
      return
    }
    actions.schedulePut({
      consumer_id: userId,
      timezone,
      order_id,
      availability_type: availabilityType,
      property_access_and_availability_notes: availabilityNotes,
      dates: this.formatDates(dates),
    })
    this.submitFormAnalytics(Object.values(dates).map((d) => {
      return d[0][2]
    }))
  }

  addDate = (key, value, index) => {
    const dates = { ...this.state.dates }
    if (key in dates) {
      dates[key].splice(index, 1)
      dates[key].push(value)
    } else {
      dates[key] = [value]
    }
    this.setState({ dates })
  }

  removeDate = (key, index) => {
    const dates = { ...this.state.dates }
    dates[key].splice(index, 1)
    delete dates[key]
    this.setState({ dates })
  }

  render() {
    const {
      consumers,
      email,
      userRole,
      order: { appraisal_scheduled, address },
    } = this.props
    const {
      availabilityType,
      availabilityNotes,
      dates,
      otherConsumerDates,
      consumerColors,
    } = this.state

    if (appraisal_scheduled) {
      return (
        <div className='flex-container'>
          <div className="box">
            <div className="success">
              <div className="icon icon-cell animate">done</div>
            </div>
            <h2>Complete</h2>
            <p className="info">
              Your inspection has already been scheduled and confirmed for {address} on {moment.utc(appraisal_scheduled).local().format('M/DD/YY h:mm A')}.
            </p>
            <p className="info">If there are any errors with this message, please contact the relevant parties immediately. </p>
            <div className="button margin-bottom" onClick={this.props.changeStep}>
              Continue
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='flex-container'>
        <div className="box calendar">
          {this.state.isError && <CalendarError
            minDays={MINIMUM_DAYS}
            minHours={MINIMUM_HOURS}
            toggleModal={this.isErrorToggle}
          />}
          <h1>Schedule </h1>  <small style={{ display: 'block' }}>for</small>
          <h2>{this.props.order.address}</h2>

          {userRole.includes('broker') && <ScheduleOptions onChange={this.onChangeInput} value={availabilityType} />}
          {availabilityType === AVAILABILITY_TYPE_UNOCCUPIED &&
            <FlexibleAvailability value={availabilityNotes} onChangeAvailabilityNotes={this.onChangeInput} />
          }
          {availabilityType === AVAILABILITY_TYPE_OCCUPIED && <>
            <div className='direction-left'>
              <p className="info margin-bottom">
                Please provide availability for at least <strong> {MINIMUM_DAYS} different days. </strong>
                With at least <strong>{MINIMUM_HOURS} hours </strong> blocked off on each day.
              </p>
              <p className="info margin-bottom">
                The appraiser will use your availability to schedule an inspection time.
                Please note, selecting your availability does <strong>not</strong> automatically schedule an inspection
              </p>
            </div>
            <Calendar
              dates={dates}
              otherConsumerDates={otherConsumerDates}
              addDate={this.addDate}
              removeDate={this.removeDate}
              consumerColors={consumerColors}
              email={email}
              fromDate={FROM_DATE}
              toDate={TO_DATE}
              fromTime={FROM_TIME}
              toTime={TO_TIME}
              minimumDuration={MINIMUM_DURATION}
              timezone={this.state.timezone}
            />
            <div className='calendar-key'>
              <h3>Key</h3>
              <p className="info margin-bottom">(Other parties may also add available times. These are color coded.)</p>
              {consumers.filter(c => consumerColors[c.id]).map(c => (<div className='d-flex' key={c.id}>
                <div className={`key-dot calm-${consumerColors[c.id]}`} />
                {c.name} ({c.email})
              </div>))}
            </div>
          </>}
          {(availabilityType === AVAILABILITY_TYPE_UNOCCUPIED || availabilityType === AVAILABILITY_TYPE_OCCUPIED) && <button className="button margin-bottom" onClick={this.submitCalendar}>
            Continue
          </button>}
        </div>
      </div>
    )
  }
}

export default WithAnalytics(ScheduleContainer)
