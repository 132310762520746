/*
Design pattern: One Ring to Rule Them All
(And we all know how that ended, with a lot of misery and destruction in the process)

This is a candidate for removal (like yesterday)
Problem with this is that our app is centered around the Schedule, not the User
Conventional routing would be problematic when users revisit our app and
we can't ask them to log in with email because if the user has more than one schedule objects,
we would always return just first object found.
Or we need to show different layout and process with multiple objects to select from,
but that would take more time and raises more questions for which we lack information about the whole platform

To keep it short, this is just bad and should be removed
Components used here are all smart components and can act on their own with minimum changes
So think of this as some internal, hidden routing, that will be removed
*/

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import q from 'query-string'

import * as userActions from 'store/actions/user'
import * as scheduleActions from 'store/actions/schedule'
import * as appActions from 'store/actions/app'

import CalendarPreview from 'pages/CalendarPreview'
import Steps from './Steps'
import { Login, Register } from './user'

import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { getLDUser } from 'helpers/IdHelpers'
import WithAnalytics from 'components/WithAnalytics'
const displayLoaderMessage = ['Welcome', 'Trouble loading payment link. Please double check the link in your email']


class Schedule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTextIdx: 0,
      ldUserId: null,
    }
  }

  componentWillMount = () => {
    const token = this.props.match?.params[0]
    const iframe = q.parse(this.props.location?.search).iframe
    const { appIframe } = this.props.appActions
    const { scheduleTokenGet } = this.props.scheduleActions
    appIframe(iframe === 'true')
    this.props.appActions.appSetToken(token)
    scheduleTokenGet(token)
  }

  componentDidUpdate(prevProps) {
    const currentOrderId = this.props.order?.id
    const prevOrderId = prevProps.order?.id
    const userID = this.props.order?.doing_business_as?.ref
    const lenderName = this.props.order?.lender_name
    const { ldUserId } = this.state
    const { ldClient } = this.props
    if (!prevOrderId && currentOrderId) {
      // We will only identify and track events ones we get the order object from the scheduleTokenGet function
      this.props.analytics.identify(currentOrderId, { name: 'Anonymous Borrower' })
      this.props.analytics.track('Consumer Tracked', { orderId: currentOrderId })
    }
    if (userID && userID !== ldUserId && ldClient) {
      ldClient.identify(getLDUser(userID, lenderName))
      this.setState({ ...this.state, ldUserId: userID })
    }
  }

  componentDidMount() {
    setTimeout(() => {
      const currentIdx = this.state.currentTextIdx
      this.setState({
        currentTextIdx: currentIdx + 1,
      })
    }, 5000)
  }

  renderComponent() {
    const { scheduleGet } = this.props.scheduleActions
    const { component } = this.props.app
    const { schedule_id } = this.props.user
    const displayTextMessage = displayLoaderMessage[this.state.currentTextIdx]
    switch (component) {
      case 'login':
        return <Login />
      case 'register':
        return <Register />
      case 'schedule':
        scheduleGet(schedule_id)
        return <h1>Loading</h1>
      case 'steps':
        return <Steps />
      case 'calendar':
        return <CalendarPreview />
      default:
        return (
          <div className='center-all'>
            <h1>{displayTextMessage}</h1>;
          </div>
        )
    }
  }

  render() {
    return <span className='text-center'>{this.renderComponent()}</span>
  }
}

const mapStateToProps = ({ app, user, order }) => ({ app, user, order })
const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch),
  scheduleActions: bindActionCreators(scheduleActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
})

export default compose(connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer(),
)(WithAnalytics(Schedule))
