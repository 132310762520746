/* global GIT_VERSION */
/* global GIT_BRANCH */
/* global GIT_COMMITHASH */
import React from 'react'
import VersionDetails from '../VersionDetails'

const API_URL = process.env.REACT_APP_API_URL

const Versions = () => {
  const feDetails = {
    'FE version': GIT_VERSION,
    'FE branch': GIT_BRANCH,
    'FE commit': GIT_COMMITHASH,
    'CBE url': API_URL,
  }

  const beFetches = [{
    url: `${API_URL}/healthchecks/check`,
    prefix: 'CBE',
  }]

  return (
    <VersionDetails
      feDetails={feDetails}
      beFetches={beFetches}
    />
  )
}

export default Versions
