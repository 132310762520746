import React, { Component } from 'react'

class CellBlock extends Component {
  render() {
    const {
      start, day, duration, startTime, endTime,
      index, time_format, updateTime, backgroundColor, className,
    } = this.props
    const style = {
      top: `calc(3em*${start / 60})`,
      left: `calc((100%/7)*${day})`,
      height: `calc(3em*${duration / 60})`,
    }
    if (backgroundColor) {
      style.backgroundColor = backgroundColor
    }
    return (
      <div
        className={`cellBox ${className || ''}`}
        style={style}
        onClick={() => { updateTime(startTime, endTime, index) }}
      >
        <span className="content">
          <p>
            {startTime.format(time_format)}
            -
            {endTime.format(time_format)}
          </p>
        </span>
      </div>
    )
  }
}

export default CellBlock
