import React from 'react'
import 'antd/dist/antd.css'
import { Input } from 'antd'

const { TextArea } = Input

const FlexibleAvailability = ({ value, onChangeAvailabilityNotes }) => {
  return (
    <div className='direction-left'>
      <p className="info margin-bottom">
        Please provide access information and any availability notes for the appraiser.
      </p>
      <TextArea rows={6} value={value} onChange={onChangeAvailabilityNotes} name='availabilityNotes' />
    </div>
  )
}

export default FlexibleAvailability
