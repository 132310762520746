/* eslint-disable max-len */

import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import * as propertyDetailActions from 'store/actions/propertyDetails'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { Divider, Select, Input, Space, Form } from 'antd'
import WithAnalytics from 'components/WithAnalytics'
const { TextArea } = Input
const { Option } = Select

const initialState = {
  occupancy: null,
  tenantLeasingInfo: null,
  landOwnership: null,
  landLeasingInfo: null,
  hoa: null,
  hoaInfo: null,
  accessoryDwellingUnit: null,
  accessoryDwellingUnitInfo: null,
  remodeling: null,
  remodelingInfo: null,
  roofProblems: null,
  roofProblemsInfo: null,
  electricalSources: null,
  gasSources: null,
  waterSupply: null,
  sewerType: null,
  coolingType: null,
  heatingType: null,
  heatingFuel: null,
  improvedSqFt: null,
  landArea: null,
  extraBuildableLot: null,
  ownershipChangePastThreeYears: null,
  ownershipChangePastThreeYearsInfo: null,
  uniqueFeatures: null,
  uniqueFeaturesInfo: null,
}

const YES = 'Yes'
const NO = 'No'
const FORM_FIELDS = Object.keys(initialState).reduce((acc, k) => ({ ...acc, [k]: k }), {})
const MULTI_SELECTS = [
  FORM_FIELDS.electricalSources,
  FORM_FIELDS.gasSources,
  FORM_FIELDS.waterSupply,
  FORM_FIELDS.sewerType,
  FORM_FIELDS.coolingType,
  FORM_FIELDS.heatingType,
  FORM_FIELDS.heatingFuel,
].reduce((acc, v) => ({ ...acc, [v]: true }))

class PropertyDetails extends React.Component {
    state = { ...initialState }
    formRef = React.createRef()

    handleInputChange(key, value, clearValues = []) {
      this.props.propertyDetailActions.setDetailValue({ key, value })
      clearValues.forEach((key) => {
        this.props.propertyDetailActions.setDetailValue({ key, value: null })
      })
    }

    onFinish = async () => {
      await this.props.propertyDetailActions.persistDetails({ details: this.props.propertyDetails, token: this.props.token })
      this.props.analytics.track('questionnaireSubmitted', {
        ...Object.entries(this.props.propertyDetails)
          .reduce((acc, [key, detail]) => ({
            ...acc,
            [detail.detail_type]: detail.value,
          }),
          {
            form_type: this.props.order?.included_forms,
          }),
      })
      this.props.changeStep()
    }

    getDetailValue(detailKey, detail) {
      if (detail.value) {
        return detail.value
      }
      if (MULTI_SELECTS[detailKey]) {
        return []
      }
      return undefined
    }

    setFormValuesFromProps() {
      const form = this.formRef
      Object.entries(this.props.propertyDetails).forEach(([key, detail]) => {
        if (detail && form.current.setFieldsValue) {
          form.current.setFieldsValue({ [key]: this.getDetailValue(key, detail) })
        }
      })
    }

    componentDidMount() {
      const token = this.props.token
      const { loadPropertyDetails } = this.props.propertyDetailActions
      loadPropertyDetails(token)
      this.setFormValuesFromProps()
    }

    componentDidUpdate() {
      this.setFormValuesFromProps()
    }

    render() {
      return (
        <div className='flex-container' style={{ textAlign: 'left' }}>
            <Form
                id='propertyDetailsForm'
                layout='vertical'
                ref={this.formRef}
                onFinish={this.onFinish}
            >
                <div className='box margin-bottom'>
                    <h1 className='text-center'>Property Details</h1>
                    <p className='info'>
                        Please provide the following information on your property.
                        This will speed up the appraisal process.
                    </p>
                    <Divider plain></Divider>
                    <p className='info margin-bottom'>
                        <b>Primary Data</b>
                    </p>

                    <Form.Item name={FORM_FIELDS.occupancy} label='Who is currently living in the home?'>
                        <Select id={FORM_FIELDS.occupancy}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails?.occupancy?.value}
                            initialValue={this.props.propertyDetails?.occupancy?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.occupancy, value, [FORM_FIELDS.tenantLeasingInfo])}>
                                <Option value='Owner Occupied'>Owner Occupied</Option>
                                <Option data-testid='tenantOccupiedOption' value='Tenant Occupied'>Tenant Occupied</Option>
                                <Option value='Unoccupied'>Unoccupied</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.occupancy?.value === 'Tenant Occupied' &&
                        <Form.Item name={FORM_FIELDS.tenantLeasingInfo} label='How much are the tenants paying per month? What is included in rent and what is the lease term?'>
                            <TextArea rows={3}
                                id='tenantLeasingInfo'
                                className='margin-bottom'
                                value={this.props.propertyDetails.tenantLeasingInfo?.value}
                                onChange={event => this.handleInputChange('tenantLeasingInfo', event.target.value)}
                            />
                        </Form.Item>
                    }

                    <Form.Item name={FORM_FIELDS.landOwnership} label='Does the owner of the home also own the land?'>
                        <Select id={FORM_FIELDS.landOwnership}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.landOwnership?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.landOwnership, value, [FORM_FIELDS.landLeasingInfo])}
                            >
                                <Option value={YES} data-testid='landOwnershipYes'>Yes</Option>
                                <Option value={NO} data-testid='landOwnershipNo'>No</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.landOwnership?.value === NO &&
                        <Form.Item name={FORM_FIELDS.landLeasingInfo} label='How much is the land leased for? When is the next renewal date?'>
                            <TextArea rows={3}
                                id={FORM_FIELDS.landLeasingInfo}
                                className='margin-bottom'
                                value={this.props.propertyDetails.landLeasingInfo?.value}
                                onChange={event => this.handleInputChange(FORM_FIELDS.landLeasingInfo, event.target.value)} />
                        </Form.Item>
                    }

                    <Form.Item name={FORM_FIELDS.hoa} label='Is there a Homeowner Association?'>
                        <Select id={FORM_FIELDS.hoa}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.hoa?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.hoa, value, [FORM_FIELDS.hoaInfo])}
                            >
                            <Option value={YES} data-testid='hoaYes'>Yes</Option>
                            <Option value={NO} data-testid='hoaNo'>No</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.hoa?.value === YES &&
                        <Form.Item name={FORM_FIELDS.hoaInfo} label='What is the fee and frequency of the HOA payments? What does it include?'>
                            <TextArea
                                rows={3}
                                id={FORM_FIELDS.hoaInfo}
                                className='margin-bottom'
                                value={this.props.propertyDetails.hoaInfo?.value}
                                onChange={event => this.handleInputChange(FORM_FIELDS.hoaInfo, event.target.value)} />
                        </Form.Item>
                    }

                    <Form.Item name={FORM_FIELDS.accessoryDwellingUnit} label='Does the property have an accessory dwelling unit (i.e. in-law apartment) and/or an accessory kitchen?'>
                        <Select
                            id={FORM_FIELDS.accessoryDwellingUnit}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.accessoryDwellingUnit?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.accessoryDwellingUnit, value, [FORM_FIELDS.accessoryDwellingUnitInfo])}>
                            <Option value={YES} data-testid='accessoryDwellingUnitYes'>Yes</Option>
                            <Option value={NO} data-testid='accessoryDwellingUnitNo'>No</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.accessoryDwellingUnit?.value === YES &&
                        <Form.Item name={FORM_FIELDS.accessoryDwellingUnitInfo} label='Please describe.'>
                            <TextArea
                            rows={3}
                            id={FORM_FIELDS.accessoryDwellingUnitInfo}
                            className='margin-bottom'
                            value={this.props.propertyDetails.accessoryDwellingUnitInfo?.value}
                            onChange={event => this.handleInputChange(FORM_FIELDS.accessoryDwellingUnitInfo, event.target.value)}
                        />
                        </Form.Item>
                    }

                    <Form.Item name={FORM_FIELDS.remodeling} label='Has there been any significant remodeling, repairs, or replacements on the property in the past 10 years (i.e. kitchen, roofing, siding, heating/cooling, etc)?'>
                        <Select
                            id={FORM_FIELDS.remodeling}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.remodeling?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.remodeling, value, [FORM_FIELDS.remodelingInfo])}
                        >
                            <Option value={YES} data-testid='remodelingYes'>Yes</Option>
                            <Option value={NO} data-testid='remodelingNo'>No</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.remodeling?.value === YES &&
                        <Form.Item name={FORM_FIELDS.remodelingInfo} label='Please describe.'>
                            <TextArea
                                rows={3}
                                id={FORM_FIELDS.remodelingInfo}
                                className='margin-bottom'
                                value={this.props.propertyDetails.remodelingInfo?.value}
                                onChange={event => this.handleInputChange(FORM_FIELDS.remodelingInfo, event.target.value)}
                            />
                        </Form.Item>
                    }

                    <Form.Item name={FORM_FIELDS.roofProblems} label='Have there been any problems with the roof?'>
                        <Select id={FORM_FIELDS.roofProblems}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.roofProblems?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.roofProblems, value, [FORM_FIELDS.roofProblemsInfo])}>
                            <Option value={YES} data-testid='roofProblemsYes'>Yes</Option>
                            <Option value={NO} data-testid='roofProblemsNo'>No</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.roofProblems?.value === YES &&
                        <Form.Item name={FORM_FIELDS.roofProblemsInfo} label='Please describe.'>
                            <TextArea
                                rows={3} id={FORM_FIELDS.roofProblemsInfo}
                                className='margin-bottom'
                                value={this.props.propertyDetails.roofProblemsInfo?.value}
                                onChange={event => this.handleInputChange(FORM_FIELDS.roofProblemsInfo, event.target.value)}
                            />
                        </Form.Item>
                    }

                    <p className='margin-top margin-bottom'>Please provide property details on the following utilities.</p>
                    <Form.Item name={FORM_FIELDS.electricalSources} label='Electrical Sources:'>
                        <Select
                            id={FORM_FIELDS.electricalSources}
                            mode='multiple'
                            placeholder='Select all that apply'
                            style={{ width: '100%' }}
                            value={this.props.propertyDetails.electricalSources?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.electricalSources, value)}
                        >
                            <Option value='Public Utilities'>Public Utilities</Option>
                            <Option value='Solar Owned' key='solarOwned'>Solar Owned</Option>
                            <Option value='Solar Leased' key='solarLeased'>Solar Leased</Option>
                            <Option value='No Electricity' key='noElectricity'>No Electricity</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={FORM_FIELDS.gasSources} label='Gas:' >
                        <Select
                            id={FORM_FIELDS.gasSources}
                            mode='multiple'
                            placeholder='Select all that apply'
                            style={{ width: '100%' }}
                            value={this.props.propertyDetails.gasSources?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.gasSources, value)}
                        >
                            <Option value='Public Utilities'>Public Utilities</Option>
                            <Option value='Propane'>Propane</Option>
                            <Option value='No Gas'>No Gas</Option>
                        </Select>
                    </Form.Item>


                    <Form.Item name={FORM_FIELDS.waterSupply} label='Water Supply:'>
                        <Select id={FORM_FIELDS.waterSupply}
                            mode='multiple'
                            placeholder='Select all that apply'
                            style={{ width: '100%' }}
                            value={this.props.propertyDetails.waterSupply?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.waterSupply, value)}
                        >
                            <Option value='Public Utilities'>Public Utilities</Option>
                            <Option value='Public Well'>Public Well</Option>
                            <Option value='Private Well'>Private Well</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={FORM_FIELDS.sewerType} label='Sewer Type:'>
                        <Select
                            id={FORM_FIELDS.sewerType}
                            mode='multiple'
                            placeholder='Select all that apply'
                            style={{ width: '100%' }}
                            value={this.props.propertyDetails.sewerType?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.sewerType, value)}
                        >
                            <Option value='Public Utilities'>Public Utilities</Option>
                            <Option value='Private Septic'>Private Septic</Option>
                            <Option value='Shared Septic'>Shared Septic</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={FORM_FIELDS.coolingType} label='Cooling Type:'>
                        <Select
                            id={FORM_FIELDS.coolingType}
                            mode='multiple' placeholder='Select all that apply'
                            style={{ width: '100%' }}
                            value={this.props.propertyDetails.coolingType?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.coolingType, value)}
                        >
                            <Option value='Central AC'>Central AC</Option>
                            <Option value='Window / Wall Units'>Window / Wall Units</Option>
                            <Option value='None'>None</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={FORM_FIELDS.heatingType} label='Heating Type:'>
                        <Select
                            id={FORM_FIELDS.heatingType}
                            mode='multiple'
                            placeholder='Select all that apply'
                            style={{ width: '100%' }}
                            value={this.props.propertyDetails.heatingType?.value ?? []}
                            onChange={value => this.handleInputChange(FORM_FIELDS.heatingType, value)}
                        >
                            <Option value='Baseboard'>Baseboard</Option>
                            <Option value='Forced Air'>Forced Air</Option>
                            <Option value='Radiant'>Radiant</Option>
                            <Option value='Other'>Other</Option>
                            <Option value='None'>None</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={FORM_FIELDS.heatingFuel} label='Heating Fuel:'>
                        <Select
                            id={FORM_FIELDS.heatingFuel}
                            mode='multiple'
                            placeholder='Select all that apply'
                            style={{ width: '100%' }}
                            value={this.props.propertyDetails.heatingFuel?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.heatingFuel, value)}
                        >
                            <Option value='Electric'>Electric</Option>
                            <Option value='Gas'>Gas</Option>
                            <Option value='Oil'>Oil</Option>
                            <Option value='Geothermal'>Geothermal</Option>
                            <Option value='Other'>Other</Option>
                            <Option value='None'>None</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className='box'>
                    <p className='info margin-bottom'>
                        <b>Data Validation</b>
                    </p>

                    <Form.Item
                        name={FORM_FIELDS.improvedSqFt}
                        label='What is the heated / cooled square footage of the improvements?'
                    >
                        <Input
                            id={FORM_FIELDS.improvedSqFt}
                            type='number'
                            addonAfter='Sq Ft.'
                            style={{ width: '35%' }}
                            className='margin-bottom'
                            value={this.props.propertyDetails.improvedSqFt?.value}
                            onChange={event => this.handleInputChange(FORM_FIELDS.improvedSqFt, event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        name={FORM_FIELDS.landArea}
                        label='What is the land area (i.e <1 AC, >1 AC to 5 AC, >5 AC)?'
                    >
                        <Input id={FORM_FIELDS.landArea}
                            className='margin-bottom'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.landArea?.value}
                            onChange={event => this.handleInputChange(FORM_FIELDS.landArea, event.target.value)} />
                    </Form.Item>

                    <Form.Item name={FORM_FIELDS.extraBuildableLot} label='Is there an extra-buildable lot?'>
                        <Select
                            id={FORM_FIELDS.extraBuildableLot}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.extraBuildableLot?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.extraBuildableLot, value)}
                        >
                            <Option value={YES}>Yes</Option>
                            <Option value={NO}>No</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item name={FORM_FIELDS.ownershipChangePastThreeYears} label='Has the property ownership changed in the past 3 years?'>
                        <Select
                            id={FORM_FIELDS.ownershipChangePastThreeYears}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.ownershipChangePastThreeYears?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.ownershipChangePastThreeYears, value, [FORM_FIELDS.ownershipChangePastThreeYearsInfo])}
                        >
                            <Option value={YES} data-testid='ownershipChangePastThreeYearsYes'>Yes</Option>
                            <Option value={NO} data-testid='ownershipChangePastThreeYearsNo'>No</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.ownershipChangePastThreeYears?.value === YES &&
                        <Form.Item name={FORM_FIELDS.ownershipChangePastThreeYearsInfo} label='Please provide the month and year of transfer, purchase price, and if the property was purchased in an open-market transaction between unrelated parties.'>
                            <TextArea
                                rows={3}
                                id={FORM_FIELDS.ownershipChangePastThreeYearsInfo}
                                className='margin-bottom'
                                value={this.props.propertyDetails.ownershipChangePastThreeYearsInfo?.value}
                                onChange={event => this.handleInputChange(FORM_FIELDS.ownershipChangePastThreeYearsInfo, event.target.value)}
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        name={FORM_FIELDS.uniqueFeatures}
                        label='Does the property have any features that are unique for properties within the neighborhood (i.e. water access, golf course frontage, etc)?'
                    >
                        <Select
                            id={FORM_FIELDS.uniqueFeatures}
                            className='margin-bottom'
                            placeholder='Please Select'
                            style={{ width: '35%' }}
                            value={this.props.propertyDetails.uniqueFeatures?.value}
                            onChange={value => this.handleInputChange(FORM_FIELDS.uniqueFeatures, value, [FORM_FIELDS.uniqueFeaturesInfo])}
                        >
                            <Option value={YES} data-testid='uniqueFeaturesYes'>Yes</Option>
                            <Option value={NO} data-testid='uniqueFeaturesNo'>No</Option>
                        </Select>
                    </Form.Item>

                    {this.props.propertyDetails.uniqueFeatures?.value === YES &&
                        <Form.Item
                            name={FORM_FIELDS.uniqueFeaturesInfo}
                            label='Please describe.'
                        >
                            <TextArea
                                rows={3}
                                id={FORM_FIELDS.uniqueFeaturesInfo}
                                className='margin-bottom'
                                value={this.props.propertyDetails.uniqueFeaturesInfo?.value}
                                onChange={event => this.handleInputChange(FORM_FIELDS.uniqueFeaturesInfo, event.target.value)}
                            />
                        </Form.Item>
                    }

                    <Form.Item>
                        <Space size='large' style={{ width: '100%', justifyContent: 'center' }}>
                            <button className='button' type='primary' value='Submit'>Submit</button>
                        </Space>
                    </Form.Item>
                </div>
            </Form>
        </div>
      )
    }
}

const mapDispatchToProps = dispatch => ({
  propertyDetailActions: bindActionCreators(propertyDetailActions, dispatch),
})

const mapStateToProps = ({ propertyDetails, app }, ownProps = {}) => ({ propertyDetails, token: app.token, ...ownProps })

export default compose(connect(mapStateToProps, mapDispatchToProps),
  withLDConsumer(),
)(WithAnalytics(PropertyDetails))
