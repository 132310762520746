import React from 'react'
import { ConfirmationModal } from '../../components/ModalTypes'

const CalendarError = ({ minDays, minHours, toggleModal }) => (
  <ConfirmationModal onClose={toggleModal}>
    <React.Fragment>
      <h1>Select a Date</h1>
      <p className="info">
        Please provide availability for at least <strong> {minDays} different days. </strong>
        With at least <strong>{minHours} hours </strong> blocked off on each day.
      </p>
    </React.Fragment>
  </ConfirmationModal>
)

export default CalendarError
