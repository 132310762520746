import React, { Component } from 'react'
import moment from 'moment-timezone'

import { groupBy } from 'utils/group-by'

import { AlertModal } from 'components/ModalTypes'
import InputTime from 'components/InputTime'
import CellBox from 'components/CellBox'

const SHORT_FORMAT = 'DD'
const LONG_FORMAT = 'YYYY-MM-DD'
const LONG_FORMAT_HOUR = 'YYYY-MM-DD-H'
const LOCALE_TIME = 'h A'

class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _now: moment({ hour: 0, minute: 0, seconds: 0 }),
      _selected: moment(),
      modal: false,
      update: -1,
      /**  The start time on the modal for adding or updating a time */
      startTime: '',
      /* The end time on the modal for adding or updating a time */
      endTime: '',
      error: '',
      timezone: moment().format('Z'),
      currentTime: '',
      adjustedOtherConsumerDates: [],
      adjustedDates: [],
    }
    this.available_timezones = []
    this.timeNow = moment()
    this.usaZones = moment.tz.zonesForCountry('US')
  }

  updateTimestamp() {
    const momentInTimeWithOffset = moment.utc().utcOffset(this.state.timezone)
    const timezoneData = this.available_timezones.find(f => f.value === this.state.timezone)
    const timezoneStamp = timezoneData ? timezoneData.name : ''
    this.setState({ currentTime: `${momentInTimeWithOffset.format('h:mm A')} ${timezoneStamp}` })
  }

  generateTimezoneSelections() {
    const dynamicZones = this.usaZones.map(f => {
      const zoneValue = moment().tz(f).format('Z')

      return {
        name: moment.tz.zone(f).abbr(this.timeNow),
        value: zoneValue,
      }
    })

    const groupByOffset = groupBy('value')
    const dynamicZonesModified = groupByOffset(dynamicZones)

    const timezoneSelections = Object.keys(dynamicZonesModified)
      .map(timezoneDelta => {
        const currentZoneGroup = dynamicZonesModified[timezoneDelta]
        const zoneAbbreviations = currentZoneGroup
          .map(f => f.name)
          .filter((value, index, self) => self.indexOf(value) === index)
          .join(', ')

        return { name: `${zoneAbbreviations}`, value: timezoneDelta }
      })

    return timezoneSelections
  }

  nextWeek = () => {
    this.setState({
      _selected: this.state._selected.add(7, 'days'),
    })
  };

  prevWeek = () => {
    const _sub = this.state._selected.clone()
    const prevWeek = _sub.subtract(7, 'days')
    if (_sub > this.state._now) {
      this.setState({
        _selected: prevWeek,
      })
    }
  };

  isValidDate = day => {
    const { fromDate, toDate } = this.props
    if (fromDate && toDate) {
      const toDateDiff = day.diff(toDate, 'days')
      const fromDateDiff = day.diff(fromDate, 'days')
      if (fromDateDiff <= 0 || toDateDiff > 0) {
        return false
      }
    }

    return true
  }

  renderHeader() {
    const { _selected, currentTime } = this.state
    const _nextWeek = _selected.clone().add(7, 'days')
    return (
      <div className="calendarHeader">
        {this.isValidDate(_selected) && <span className="icon clickable left margin-left" onClick={this.prevWeek}>
          keyboard_arrow_left
        </span>}
        { /* eslint-disable-next-line react/no-string-refs */}
        <span ref="header">
          {_selected.format('MMM DD')} - {_nextWeek.format('MMM DD, YYYY')}
        </span>
        {this.isValidDate(_nextWeek) && <span className="icon clickable right margin-right" onClick={this.nextWeek}>
          keyboard_arrow_right
        </span>}

        <div>
          <small style={{ fontSize: '75%', fontWeight: 'normal' }}>Property Local Time: {currentTime}</small>
        </div>
      </div>
    )
  }

  renderDates() {
    const days = []
    for (let i = 0; i < 7; i++) {
      const day = this.state._selected.clone().add(i, 'd')
      days.push(
        <span key={i}>
          <div className={this.isValidDate(day) ? 'date' : 'disabled date'}>
            <div className="dateText">
              <b>{day.format(SHORT_FORMAT)}</b>
              {day.format('ddd')}
            </div>
          </div>
        </span>,
      )
    }

    return <div className="calendarDates">{days}</div>
  }

  renderDiv = (value, day) => {
    const boxes = []
    let index = -1

    const cellBoxProps = {
      value,
      removeDate: this.props.removeDate,
      time_format: LOCALE_TIME,
    }

    const {
      adjustedOtherConsumerDates,
      adjustedDates,
    } = this.state

    const myTargetDates = adjustedDates
    const otherTargetDates = adjustedOtherConsumerDates

    // show dates selected by other consumers
    if (otherTargetDates && value in otherTargetDates) {
      for (const time of otherTargetDates[value]) {
        ++index
        const [start, duration, startTime, endTime, email] = time
        boxes.push(
          <CellBox
            id={time}
            start={start}
            day={day}
            duration={duration}
            startTime={startTime}
            endTime={endTime}
            index={index}
            className={`calm-${this.props.consumerColors[email]}`}
            updateTime={() => ({})}
            {...cellBoxProps}
          />,
        )
      }
    }
    // show this user's dates
    if (myTargetDates && value in myTargetDates) {
      for (const time of myTargetDates[value]) {
        ++index
        const [start, duration, startTime, endTime, email] = time
        boxes.push(
          <CellBox
            id={time}
            start={start}
            day={day}
            duration={duration}
            startTime={startTime}
            endTime={endTime}
            index={index}
            updateTime={this.updateTime}
            className={`calm-${this.props.consumerColors[email]}`}
            {...cellBoxProps}
          />,
        )
      }
    }


    return <React.Fragment>{boxes}</React.Fragment>
  };

  isValidHour = hour => {
    const { toTime, minimumDuration } = this.props
    const hourAllowToSelect = toTime - minimumDuration + 1
    if (hourAllowToSelect <= hour) {
      return false
    }
    return true
  }

  renderDateTimeCells(hour) {
    const cells = []
    for (let i = 0; i < 7; i++) {
      const day = this.state._selected.clone().add(i, 'd')
      const formattedDate = `${day.format(LONG_FORMAT)}-${hour}`
      cells.push(
        <React.Fragment key={`${formattedDate}-${i}`}>
          <button
            className={this.isValidDate(day) && this.isValidHour(hour) ? 'cell' : 'disabled cell'}
            onClick={() => this.clickCell(formattedDate)}
            role="button"
            title={formattedDate}
            disabled={!this.isValidDate(day) || !this.isValidHour(hour)}
          >
            <span className="icon">add_circle_outline</span>
          </button>
          {this.renderDiv(formattedDate, i)}
        </React.Fragment>,
      )
    }
    return <div className="timeCells">{cells}</div>
  }

  renderTime() {
    const time = []
    let { fromTime, toTime } = this.props
    fromTime = fromTime || 0
    toTime = toTime || 24
    for (let hour = fromTime; hour < toTime + 1; hour++) {
      time.push(
        <div className="dateTime" key={hour}>
          <div className="hours" key={hour}>
            <span id={moment({ hour, minute: 0 }).format(LOCALE_TIME)}>
              {moment({ hour, minute: 0 }).format(LOCALE_TIME)}
            </span>
          </div>

          {this.renderDateTimeCells(hour)}
        </div>,
      )
    }
    return (
      <div className="time" id="time-container">
        {time}
      </div>
    )
  }

  handleCloseModal = () => {
    this.setState({
      modal: false,
      update: -1,
    })
  }

  renderPopup() {
    const { fromTime, toTime } = this.props
    return (
      <AlertModal
        onClose={this.handleCloseModal}
        onSubmit={this.onTimeSubmit}
        submitText={this.state.update > -1 ? 'Update' : ''}
      >
        <h1>Pick a Time</h1>
        <p className="intro">
          Please provide your availability for <b>{this.state.startTime.format('dddd, MMMM Do YYYY')}</b>
        </p>
        <p className="intro margin-bottom">
          You must provide a minimum of <strong>3 hours</strong>
        </p>
        <div>
          <span className="text-right-input">FROM:</span>
          <InputTime time={this.state.startTime} onChange={this.updateField} fieldID="startTime" fromTime={fromTime} toTime={toTime} />
        </div>
        <div>
          <span className="text-right-input">TO:</span>
          <InputTime time={this.state.endTime} onChange={this.updateField} fieldID="endTime" fromTime={fromTime} toTime={toTime} />
        </div>
        {this.state.error && <p className="form-error">{this.state.error}</p>}
        <br />
        {this.state.update > -1 && (
          <div className="button no-transform error" onClick={() => this.removeDate()}>
            Delete
          </div>
        )}
      </AlertModal>
    )
  }

  clickCell = value => {
    let { minimumDuration } = this.props
    minimumDuration = minimumDuration || 1
    const startTime = moment(value, LONG_FORMAT_HOUR)

    let endTime = {}
    if (startTime.format('HH') === '23') {
      endTime = startTime.clone().add(55, 'minutes')
    } else {
      endTime = startTime.clone().add(minimumDuration, 'hour')
    }
    this.setState({
      modal: true,
      startTime,
      endTime,
    })
  };

  updateField = (value, fieldID) => {
    this.setState({
      [fieldID]: value,
    })
  };

  errorMessageCreation = () => {
    const {
      toTime,
      minimumDuration,
    } = this.props
    const { startTime, endTime } = this.state
    const timeDiffInMin = endTime.diff(startTime) / 60000
    const minimumDurationInMinutes = minimumDuration * 60
    const toTimeMoment = endTime.clone().set({ h: toTime, m: 0, s: 0 })
    const isValidEndTime = endTime <= toTimeMoment
    if (timeDiffInMin < minimumDurationInMinutes) {
      return { error: `An end time was set that is below the ${minimumDuration} hour minimum required.` }
    }
    if (!isValidEndTime) {
      return { error: `An end time was set that is above the range allowed. Please select a time before ${toTimeMoment.format('h:mm:ss a')}` }
    }
    return { error: null }
  }

  onTimeSubmit = () => {
    const {
      email,
    } = this.props
    const { startTime, endTime } = this.state
    const timeDiffInMin = (endTime - startTime) / 60000

    const { error } = this.errorMessageCreation()
    this.setState({ error })
    if (!error) {
      this.props.addDate(
        startTime.format(LONG_FORMAT_HOUR),
        [startTime.format('mm'), timeDiffInMin, startTime, endTime, email],
        this.state.update,
      )
      this.setState({ modal: false, update: -1, error: '' })
    }
  };

  updateTime = (startTime, endTime, index) => {
    this.setState({
      startTime,
      endTime,
      modal: true,
      update: index,
      error: '',
    })
  };

  removeDate = () => {
    this.props.removeDate(this.state.startTime.format(LONG_FORMAT_HOUR), this.state.update)
    this.setState({
      modal: true,
      update: -1,
      error: '',
    })
  };

  componentDidMount() {
    const { timezone } = this.props
    this.setState({
      timezone: timezone,
    }, () => {
      this.adjustMyDates()
      this.adjustOthersDates()
      this.updateTimestamp()
    })

    this.available_timezones = this.generateTimezoneSelections()
    this.timerIntervalId = setInterval(() => {
      this.updateTimestamp()
    }, 1000 * 60)
  }

  componentWillUnmount() {
    clearInterval(this.timerIntervalId)
  }

  componentDidUpdate(prevProps) {
    const timezoneChanged = prevProps.timezone !== this.props.timezone
    const datesChanged = prevProps.dates !== this.props.dates
    const otherConsumerDatesChanged = prevProps.otherConsumerDates !== this.props.otherConsumerDates

    if (timezoneChanged) {
      this.setState({
        timezone: this.props.timezone,
        _now: moment({ hour: 0, minute: 0, seconds: 0 }).utcOffset(this.props.timezone),
        _selected: moment().utcOffset(this.props.timezone),
      },
      () => {
        this.updateTimestamp()
        this.adjustMyDates()
        this.adjustOthersDates()
      }
      )
    } else if (datesChanged || otherConsumerDatesChanged) {
      this.adjustMyDates()
      this.adjustOthersDates()
    }
  }

  adjustMyDates() {
    this.setState({ adjustedDates: this.formatDatesHere(this.props.dates) })
  }

  adjustOthersDates() {
    this.setState({ adjustedOtherConsumerDates: this.formatDatesHere(this.props.otherConsumerDates) })
  }

  formatDatesHere(dates) {
    // TODO: This is here to provide a means to convert date times to show on a calendar.
    const inputDates = Object.assign({}, dates)
    // for (const date in inputDates) {
    //   for (const time of inputDates[date]) {

    // console.log('formatDatesHere:before', time, 'with:', this.state.timezone)
    //  time[2] = moment(time[2]).utcOffset(this.state.timezone)
    //  time[3] = moment(time[3]).utcOffset(this.state.timezone)
    // console.log('formatDatesHere:after', time, 'with:', this.state.timezone)
    //   }
    // }
    return inputDates
  }

  render() {
    return (
      <div className="calendarApp">
        {this.state.modal && this.renderPopup()}
        {this.renderHeader()}
        {this.renderDates()}
        {this.renderTime()}
      </div>
    )
  }
}

export default Calendar
