import { handleActions } from 'redux-actions'

import { scheduleActions } from '../types'

const initialState = {
  _id: '',
  evault_id: '',
  merchant_id: '',
  appraisal_type: '',
  available_dates: [],
  steps: [],
}

export default handleActions(
  {
    [scheduleActions.SCHEDULE_SET]: (state, action) => ({
      available_dates: state.available_dates,
      ...action.payload,
    }),
  },
  initialState,
)
