export default function processError(result) {
  if (result.response && result.response.data) {
    if (result.response.data.errors) {
      return result.response.data.errors
    }
    if (result.response.data.error) {
      return [result.response.data.error]
    }
  }
  if (result.message) {
    // this includes uncaught 500s and possibly other errors. If it is
    // _only_ the uncaught 500s, we may want to return something more
    // user friendly that just 'Internal Server Error' which is what
    // result.message is in those cases.
    return [result.message]
  }
  if (typeof result === 'string') {
    // Auth errors. This could include logging in with wrong credentials,
    // or making a request that requires jwt without the authorization
    // header.
    return [result]
  }
  if (result.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    return [result.request]
  }
  console.log('processError has encountered an error with unexpected format:', result)
  return ["We're sorry, but an unknown error has occurred."]
}
