import React from 'react'

const PwResetModal = () => (
  <React.Fragment>
    <div className="success">
      <div className="icon icon-cell animate">done</div>
    </div>
    <h1>Password Reset</h1>
    <p className="info">
      Please check your email for further information<br />
      Your link expires in 4 hours
    </p>
  </React.Fragment>
)

export default PwResetModal
