import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CreditCards from 'static/img/creditcards.jpg'
import { apiToReadable } from '../../helpers/Request'
import * as orderActions from '../../store/actions/order'
import * as appActions from '../../store/actions/app'
import * as creditCardActions from '../../store/actions/creditcard'

const stateRegex = /(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$/i

let buttonHasBeenClicked = false

const VeryGoodSecurityForm = () => {
  return (
    <form id="cc-form" className="fullwidth">
      <img className="cc-img" src={CreditCards} alt="acceptable credit cards" />
      <div className="payment-row">
        <div className="payment-row-mobile">
          <div id="cc-first-name" className="form-group flex-1">
            <p className='d-flex info'>Cardholder First Name</p>
            <span className="vgs-input"></span>
          </div>
          <div id="cc-last-name" className="form-group flex-1">
            <p className='d-flex info'>Cardholder Last Name</p>
            <span className="vgs-input"></span>
          </div>
          <div id="cardholder_email" className="form-group flex-1">
            <p className='d-flex info'>Cardholder Email</p>
            <span className="vgs-input"></span>
        </div>
        </div>
      </div>
      <div className="payment-row">
        <div className="payment-row-mobile">
          <div id="cc-number" className="form-group flex-1">
            <div className="form-control-static">
              <p className='d-flex info'>Card Number</p>
              <span className="vgs-input"></span>
            </div>
          </div>
          <div id="cc-expiration-date" className="form-group flex-1">
            <div className="form-control-static">
              <p className='d-flex info'>Expiration Date</p>
              <span className="vgs-input"></span>
            </div>
          </div>
          <div id="cc-cvc" className="form-group flex-1">
            <div className="form-control-static">
              <p className='d-flex info'>CVC</p>
              <span className="vgs-input"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-row">
        <div className="payment-row-mobile">
          <div id="cc-billing-street" className="form-group flex-1">
            <p className='d-flex info'>Billing Street</p>
            <span className="vgs-input"></span>
          </div>
        </div>
        <div className="payment-row-mobile">
          <div id="cc-billing-city" className="form-group flex-1">
            <p className='d-flex info'>Billing City</p>
            <span className="vgs-input"></span>
          </div>
        </div>
        <div className="payment-row-mobile flex-2">
          <div id="cc-billing-state" className="form-group flex-1">
            <p className='d-flex info'>Billing State</p>
            <span className="vgs-input"></span>
          </div>
          <div id="cc-billing-zip" className="form-group flex-1">
            <div className="form-control-static">
              <p className='d-flex info'>Billing Zip</p>
              <span className="vgs-input"></span>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

const AddCreditCard = (props) => {
  return (
    <div className="flex-container text-center">
      <div className="box fullwidth">
        {props.ccType ? (<h1>{props.ccType == 'lender' ? 'Lender' : 'Branch'} Credit Card</h1>) : <h1>Payment</h1>}
        <Fragment>
          <VeryGoodSecurityForm/>
          <div 
            className={`${props.disabledButton ? 'button-disabled' : 'button'}`} 
            onClick={props.disabledButton || props.buttonHasBeenClicked ? props.disabledError : props.creditCardSubmit}
          >
            Save
          </div>
        </Fragment>
      </div>
    </div>
  )
}

const PaymentNeeded = (props) => {
  return <div className="flex-container text-center">
    <div className="box fullwidth">
      <Fragment>
        <p className='info'>{props.borrower_payment_text}</p>
        <p>
          Your {props.borrowerPaymentText} {props.activePayment.up_to_amount ? 'will be up to' : 'is'} <b>${props.activePayment.up_to_amount ? (props.activePayment.up_to_amount / 100).toFixed(2) : (props.activePayment.amount / 100).toFixed(2)}</b><br />
          All payments must be made before proceeding with the appraisal
        </p>

        <VeryGoodSecurityForm/>

        <div className={`${props.disabledButton ? 'button-disabled' : 'button'}`} onClick={props.disabledButton || props.buttonHasBeenClicked ? props.disabledError : props.triggerPaymentSubmit}>
          Submit Payment
        </div>
      </Fragment>
    </div>
  </div>
}

const NoPaymentNeeded = (props) => {
  return <div className="flex-container text-center">
    <div className="box fullwidth">
      <Fragment>
        <p className='info'>
          Great news! We have already received payment for this appraisal. You do not need to pay at this time.
        </p>
        <div className="button" onClick={props.changeStep}>
          Continue
        </div>
      </Fragment>
    </div>
  </div>
}

const CardCaptured = (props) => {
  return <div className="flex-container text-center">
    <div className="box fullwidth">
      <Fragment>
        {props.capturedCards}
        {props.receipts}
        {!props.activePayment && <div className="button" onClick={props.changeStep}>
          Continue
  </div>}
      </Fragment>
    </div>
  </div>
}
class Payment extends Component {
  form = {}

  state = {
    shouldSubmitPayment: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.shouldSubmitPayment && this.state.shouldSubmitPayment) {
      this.submit()
      this.setState({
        shouldSubmitPayment: false
      })
    }
  }

  triggerPaymentSubmit = () => {
    if (!this.state.shouldSubmitPayment) {
      this.setState({
        shouldSubmitPayment: true
      })
    }
  }

  getActivePayment = () => {
    const { schedule: { payments }, user } = this.props
    let activePayment = payments.find(p => !p.paid && !p.card_information_captured && (p.consumer == user.id || p.payment_type == 'lender') && p.payment_type !== 'manual')
    return activePayment
  }

  componentDidMount() {
    const { credit_card } = this.props
    let activePayment = credit_card.cc_type ? '' : this.getActivePayment()

    // If the payment is an integration payment then we should initialize the
    // Very good security form
      if (credit_card.cc_type || (activePayment && this.props.balance.amount > 0)) {
      this.form = window.VGSCollect.create(process.env.REACT_APP_VGS_TOKEN, state => { });
      this.setState({
        cardNumberError: false,
        cardExpiryError: false,
        cardCVCError: false,
      })
      // Add all the fields and validators to the form
      this.form.field('#cc-first-name .vgs-input', {
        type: 'text',
        name: 'card_first_name',
        placeholder: 'John',
        validations: ['required'],
      });

      this.form.field('#cc-last-name .vgs-input', {
        type: 'text',
        name: 'card_last_name',
        placeholder: 'Smith',
        validations: ['required'],
      });

      this.form.field('#cardholder_email .vgs-input', {
        type: 'text',
        name: 'cardholder_email',
        placeholder: 'email@example.com',
        validations: ['required'],
        autoComplete: 'email'
      });

      this.form.field('#cc-number .vgs-input', {
        type: 'card-number',
        name: 'card_number',
        showCardIcon: true,
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '1234 1234 1234 1234',
        validations: ['required', 'validCardNumber'],
      });

      this.form.field('#cc-billing-street .vgs-input', {
        type: 'text',
        name: 'billing_street',
        errorColor: '#D8000C',
        placeholder: '123 Main St.',
        validations: ['required'],
      });

      this.form.field('#cc-billing-city .vgs-input', {
        type: 'text',
        name: 'billing_city',
        errorColor: '#D8000C',
        placeholder: 'Boston',
        validations: ['required'],
      });

      this.form.field('#cc-billing-state .vgs-input', {
        type: 'text',
        name: 'billing_state',
        errorColor: '#D8000C',
        placeholder: 'MA',
        maxLength: 2,
        validations: ['required', stateRegex],
        css: {
          textTransform: 'uppercase'
        }
      });

      this.form.field('#cc-billing-zip .vgs-input', {
        type: 'zip-code',
        name: 'billing_zip',
        successColor: '#4F8A10',
        errorColor: '#D8000C',
        placeholder: '12345',
        validations: ['required', 'postal_code/us'],
      });

      this.form.field('#cc-cvc .vgs-input', {
        type: 'card-security-code',
        name: 'additional_reggora_data',
        placeholder: 'CVC',
        validations: ['required', 'validCardSecurityCode'],
      });

      this.form.field('#cc-expiration-date .vgs-input', {
        type: 'card-expiration-date',
        name: 'card_expiration',
        placeholder: 'MM / YY',
        serializers: [this.form.SERIALIZERS.separate({ monthName: 'ExpirationDateMonth', yearName: 'ExpirationDateYear' })],
        validations: ['required', 'validCardExpirationDate']
      });
    }
  }

  creditCardSubmit = () => {
    const {
      creditCardActions: { addCreditCard },
      appActions: { displayError },
      credit_card: { cc_type, id }
    } = this.props

    const last_four = this.form.state.card_number.last4

    // Handle very good security post
    this.form.submit('/post', {}, (status, response) => {
      // This function returns the tokenized data
      if (status !== 200) {
        return displayError(['Error Processing Payment Information'])
      }
      const data = {}
      data.payment_info = JSON.parse(response.data)
      data.cc_type = cc_type
      data.id = id
      data.last_four = last_four
      addCreditCard(data)
    }, function (errors) {
      let displayErrors = []
      for (const key in errors) {
        displayErrors = displayErrors.concat(errors[key].errorMessages.map(err => `${apiToReadable(key)} ${err}`))
      }
      displayError(displayErrors)
    });
  }

  submit = async () => {
    const {
      orderActions: { submitPayment },
      appActions: { displayError },
      order: { id: order_id },
      user: { id: client }
    } = this.props
    const data = {
      client,
      order_id,
    }

    if (!buttonHasBeenClicked) {
      buttonHasBeenClicked = true
    } else {
      return
    }
    // Handle very good security post
    this.form.submit('/post', {}, (status, response) => {
      // This function returns the tokenized data
      if (status !== 200) {
        return displayError(['Error Processing Payment Information'])
      }
      data.payment_info = JSON.parse(response.data)
      submitPayment(data)
      buttonHasBeenClicked = false
    }, function (errors) {
      // errors object:
      //{
      //  <invalid field name>: {
      //    <field state>
      //  },
      //}
      let displayErrors = []
      for (const key in errors) {
        let readableKey = key
        readableKey = readableKey.replace('additional_reggora_data', 'CVC')
        readableKey = apiToReadable(readableKey)
        displayErrors = displayErrors.concat(errors[key].errorMessages.map(err => `${readableKey} ${err}`))
      }
      displayError(displayErrors)
      buttonHasBeenClicked = false
    });
  };

  isDisabled = () => {
    if (this.state.shouldSubmitPayment) {
      return true
    }
    return false
  }

  disabledError = () => {
    const {
      appActions: { displayError },
    } = this.props
    displayError(['Invalid Payment Details'])
  }

  render() {
    const {
      order: {
        borrower_payment_text
      },
      schedule: {
        payments,
        lender
      },
      user,
      credit_card
    } = this.props

    let activePayment = credit_card.cc_type ? '' : this.getActivePayment()
    const ccType = this.props.credit_card.cc_type
    const balance = this.props.balance.amount
    const disabledButton = this.isDisabled()

    let capturedCards = credit_card.cc_type || lender ? '' : payments.filter(p => !p.paid && p.card_information_captured && p.consumer == user.id && p.payment_type !== 'manual').map(p => {
      return (<div key={p.id} className='my-6'><p>Your payment is being processed. Your receipt will be available here upon completion.</p></div>)
    })

    let receipts = credit_card.cc_type || lender ? '' : payments.filter(p => p.paid && p.consumer == user.id && p.payment_type !== 'manual').map(p => {
      return (<div key={p.id} className='my-6'><p>A payment for this appraisal has been received.</p>
        {p.receipt_url && <p><a target="_blank" href={p.receipt_url}>View receipt</a></p>}</div>)
    })

    if (ccType) {
      if (activePayment) {
        return <CardCaptured 
                  capturedCards={capturedCards} 
                  receipts={receipts} 
                  activePayment={activePayment} 
                  changeStep={this.props.changeStep}/> 
      } else {
        return <AddCreditCard 
                  ccType={ccType}
                  disabledButton={disabledButton}
                  buttonHasBeenClicked={buttonHasBeenClicked}
                  creditCardSubmit={this.creditCardSubmit}
                  disabledError={this.disabledError} />
      }
    }
    if (balance > 0 && activePayment) {
      return <PaymentNeeded 
                borrowerPaymentText={borrower_payment_text}
                ccType={ccType}
                activePayment={activePayment}
                disabledButton={disabledButton}
                buttonHasBeenClicked={buttonHasBeenClicked}
                creditCardSubmit={this.creditCardSubmit}
                disabledError={this.disabledError}
                balance={balance}
                triggerPaymentSubmit={this.triggerPaymentSubmit} />
    } else {
      return <NoPaymentNeeded changeStep={this.props.changeStep}/>
    }
  }
};

const mapStateToProps = ({ app, schedule, user, order, credit_card, balance}) => ({ app, schedule, user, order, credit_card, balance});

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(appActions, dispatch),
  orderActions: bindActionCreators(orderActions, dispatch),
  creditCardActions: bindActionCreators(creditCardActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
