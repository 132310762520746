import { handleActions } from 'redux-actions'

import { userActions } from '../types'

const initialState = {
  _id: '',
  name: '',
  email: '',
  role: '',
  schedule_id: '',
  lender: false,
  registered: false,
  permissions: [],
}

export default handleActions(
  {
    [userActions.IS_LENDER]: (state, action) => ({
      ...state,
      lender: action.payload.lender,
    }),
    [userActions.USER_SET]: (state, action) => ({
      ...action.payload.data,
    }),
    [userActions.USER_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState,
)
