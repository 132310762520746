import React from 'react';
import sassVariables from '../static/style/_variables.scss';

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth)
  // Add a second state variable "height" and default it to the current window height
  const [height, setHeight] = React.useState(window.innerHeight)

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth)
      // Set the height in state as well as the width
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const breakpointMediumNum = sassVariables.breakpointMedium.slice(0, -2);
  const isMediumScreenOrBelow = width <= breakpointMediumNum;

  // Return both the height and width
  return { width, height, isMediumScreenOrBelow }
}

export default useViewport;
