import React from 'react'
import { Progress } from 'antd'

const MobileStepper = (props) => {
  const {
    step,
    steps
  } = props

  const stepNumber = step + 1;
  const percentComplete = (stepNumber / steps.length) * 100

  return (
    <div className="header-steps-mobile">
      <div className="step-title-container">
        <div className="step-title">{steps[step]}</div>
        {stepNumber < steps.length && (
          <div className="next-step-title">Next: {steps[stepNumber]}</div>
        )}
      </div>
      <Progress
        type="circle"
        percent={percentComplete}
        format={() => `${stepNumber} of ${steps.length}`}
        width={50}
        strokeWidth={10}
      />
    </div>
  )
}

export default MobileStepper
