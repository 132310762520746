import {
  createAction,
} from 'redux-actions'
import {
  apiRequest,
} from '../../helpers/Request'
import * as appActions from './app'
import {
  creditCardActions,
} from '../types'

export const creditCardSet = createAction(creditCardActions.CREDITCARD_SET)
export const creditCardUpdate = createAction(creditCardActions.CREDITCARD_UPDATE)

// This submitPayment needs to be changed to handle saving credit card
export const addCreditCard = data => async dispatch => {
  dispatch(appActions.appLoading(true))
  apiRequest({
    endpoint: '/creditcard/corporate-card',
    method: 'post',
    data,
  }).then(({ status, data: responseData }) => {
    if (status === 200) {
      dispatch(appActions.appLoading(false))
      dispatch(appActions.appSuccesses(['Your card has been added.']))
      dispatch(appActions.appStepIncrement())
    } else {
      const { errors } = responseData
      dispatch(appActions.appLoading(false))
      dispatch(appActions.appErrors(errors))
    }
  }).catch((err) => {
    dispatch(appActions.appLoading(false))
    dispatch(appActions.appErrors(['Card could not be added. Please check that you have entered your information correctly']))
  })
}
