import React from 'react';

const ErrorPage = () => (
  <div className="container small">
    <div className="center-all">
      <div className="box">
        <h1>Page not found</h1>
        <p className="intro">Please try again following link from email you received</p>
      </div>
    </div>
  </div>
);

export default ErrorPage;
