class Validator {
  static required(value) {
    if (typeof value === 'undefined' || value.toString().trim().length < 1) {
      return 'Field required'
    } else {
      return false
    }
  }

  static checkLength(value, minLength) {
    const charNum = value.toString().trim()
    if (charNum && charNum.length < minLength) {
      return `Min ${minLength} characters required`
    } else {
      return false
    }
  }

  static isInteger(value) {
    const regex = /^\d+$/
    if (value.toString().trim() && !regex.test(value)) {
      return 'Please provide numbers only'
    } else {
      return false
    }
  }

  static sameInput(firstValue, secondValue) {
    if (firstValue !== secondValue) {
      return 'Entries do not match'
    } else {
      return false
    }
  }


  static validateFromObj(obj) {
    const errors = {}
    let hasErrors = 0
    for (const key in obj) {
      errors[key] = obj[key]
    }
    for (const key in errors) {
      errors[key] = errors[key].filter(item => item !== false)
      if (errors[key].length) {
        ++hasErrors
      }
    }
    return [hasErrors, errors]
  }
}

export default Validator
