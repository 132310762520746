import { handleActions } from 'redux-actions'

import { propertyDetailActions } from '../types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

const initialState = {
  occupancy: null,
  tenantLeasingInfo: null,
  landOwnership: null,
  landLeasingInfo: null,
  hoa: null,
  hoaInfo: null,
  accessoryDwellingUnit: null,
  accessoryDwellingUnitInfo: null,
  remodeling: null,
  remodelingInfo: null,
  roofProblems: null,
  roofProblemsInfo: null,
  electricalSources: null,
  gasSources: null,
  waterSupply: null,
  sewerType: null,
  coolingType: null,
  heatingType: null,
  heatingFuel: null,
  improvedSqFt: null,
  landArea: null,
  extraBuildableLot: null,
  ownershipChangePastThreeYears: null,
  ownershipChangePastThreeYearsInfo: null,
  uniqueFeatures: null,
  uniqueFeaturesInfo: null,
}

export const setDetailValue = (state, action) => {
  const { key, value } = action.payload
  const existingDetail = state[key] ?? {}
  return {
    ...state,
    [key]: {
      ...existingDetail,
      value: value,
    },
  }
}

export const setDetails = (state, action) => ({
  ...state,
  ...action.payload,
})

export default handleActions(
  {
    [propertyDetailActions.SET_DETAILS]: setDetails,
    [propertyDetailActions.SET_DETAIL_VALUE]: setDetailValue,
  },
  initialState,
)
