import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import {
  appStepIncrement,
  appLoading,
  appModal,
} from 'store/actions/app'
import { schedulePayment, schedulePut } from 'store/actions/schedule'
import * as orderActions from '../store/actions/order'
import { Intro, Payment, Success } from './shared'
import PropertyDetails from './PropertyDetails'
import ScheduleContainer from './ScheduleContainer'
import Stepper from 'components/Stepper'
class Steps extends Component {
    nextStep = () => {
      const {
        actions,
      } = this.props
      actions.appStepIncrement()
    }

    getSteps = () => {
      const {
        schedule,
        user,
        order,
        credit_card = {},
        balance,
        actions,
      } = this.props
      const result = []

      const consumerDate = schedule.consumer_dates?.find(date => date.consumer === user.id)

      // first push the intro
      if (!user.lender && !credit_card.id) {
        result.push(<Intro
                permissions={user.permissions.join(',')}
                name={user.name}
                balance={balance.amount}
                order={order}
                lenderName={order.lender_name}
                branchName={order.doing_business_as?.email_sender_name}
                additionalText={''}
                changeStep={this.nextStep}
                borrower_payment_text={order.borrower_payment_text}
                schedule={schedule}
                user={user}
                />)
      }

      if (credit_card.id || user.permissions.includes('payment')) {
        result.push(
                  <Payment changeStep={this.nextStep}/>
        )
      }
      if (!credit_card.id && user.permissions.includes('schedule')) {
        result.push(<ScheduleContainer
                addDate={this.addDate}
                removeDate={this.removeDate}
                changeStep={this.nextStep}
                consumers={schedule.consumers}
                consumerDates={schedule.consumer_dates}
                availabilityNotes={consumerDate?.property_access_and_availability_notes}
                availabilityType={consumerDate?.availability_type}
                userRole={user.role}
                userId={user.id}
                email={user.email}
                actions={actions}
                order={order}
            />)
        result.push(<PropertyDetails
                changeStep={this.nextStep}
                consumers={schedule.consumers}
                email={user.email}
                order={order}
                actions={actions}
            />)
      }

      if (credit_card.cc_type || user.permissions && user.permissions.length) {
        let type = !credit_card.cc_type && user.permissions && user.permissions.includes('schedule') ? 'calendar' : 'payment'
        if (credit_card.cc_type) {
          type = 'creditcard'
        }
        result.push(<Success type={type} changeStep={this.nextStep} />)
      }
      return result
    }

    render() {
      const {
        app,
      } = this.props
      return (<Stepper step={app.step}>
            {this.getSteps()}
        </Stepper>)
    }
}

const mapStateToProps = ({
  app,
  schedule,
  user,
  order,
  payment_type,
  credit_card,
  balance,
}) => ({ app, schedule, user, order, payment_type, credit_card, balance })

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({
      appStepIncrement,
      schedulePayment,
      appLoading,
      appModal,
      schedulePut,
      ...orderActions,
    }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps)
