import React from 'react'


class Stepper extends React.Component {
  renderStep() {
    return this.props.children.map((item, i) => (
          <span
            key={i}
            className={'animate'}
            style={ i === this.props.step ? { display: 'block' } : { display: 'none' }}>
            {item}
          </span>
    ))
  }

  render() {
    return (
      <div>
        {this.renderStep()}
      </div>
    )
  }
}

export default Stepper
