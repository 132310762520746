import React from 'react';
import { Router, Route } from 'react-router-dom';

import Routes from 'routes';
import { history } from 'store';
import { compose } from 'redux'

import ErrorPage from 'pages/404';
import Navigation from 'pages/Navigation';
import Schedule from 'pages/Schedule';
import Consent from './pages/Consent';

import { PasswordReset } from 'pages/user';

import 'static/style/style.css';
import Steps from './pages/Steps';


import { withLDProvider } from 'launchdarkly-react-client-sdk'
import Versions from './components/Versions'

const App = () => (
  <Router history={history}>
    <div className="App">
      <Navigation />
      <main>
        <Route exact path={Routes.schedule} component={Schedule} />
        <Route path={Routes.password_reset} component={PasswordReset} />
        <Route path={Routes.error} component={ErrorPage} />
        <Route path={Routes.consent} component={Consent} />
        {/* <Route path={Routes.register} component={Register} /> */}
      </main>
      <div class="powered-by">
        <p>Powered by</p><b class="reggora-blue">Reggora</b>
      </div>
      <Versions />
    </div>
  </Router>
);

export default compose(
  withLDProvider({
    clientSideID: process.env.LD_CLIENT_ID,
    user: {
      key: 'unidentified_init_user',
      type: 'LENDER'
    },
  })
)(App);
