import React from 'react'
import { findDOMNode } from 'react-dom'

class Modal extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.clickModus)
    }, 10)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickModus)
  }

  clickModus = e => {
    const modusContent = this.refs.modusContent
    if (modusContent) {
      const component = findDOMNode(modusContent)
      if (!component.contains(e.target)) {
        this.props.onClose()
      }
    }
  };

  render() {
    return (
      <div className="modal">
        <div className="modal-container" ref="modusContent">
          <div className="icon close" onClick={this.props.onClose}>
            close
          </div>
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default Modal
