import {
  createAction,
} from 'redux-actions'
import {
  apiRequest,
} from '../../helpers/Request'
import * as appActions from './app'
import {
  orderActions,
} from '../types'

export const orderSet = createAction(orderActions.ORDER_SET)


export const submitPayment = data => async dispatch => {
  dispatch(appActions.appLoading(true))
  apiRequest({
    endpoint: '/order/payment',
    method: 'post',
    data,
  }).then(({ status, data: responseData }) => {
    if (status === 200) {
      dispatch(appActions.appLoading(false))
      dispatch(appActions.appSuccesses(['Your payment has been processed.']))
      dispatch(appActions.appStepIncrement())
    } else {
      const { errors } = responseData
      dispatch(appActions.appLoading(false))
      dispatch(appActions.appErrors(errors))
    }
  }).catch((err) => {
    const errors = err.response && err.response.data && err.response.data.errors ? err.response.data.errors : ['Payment could not be processed. Please check that you have entered your payment information correctly']
    dispatch(appActions.appLoading(false))
    dispatch(appActions.appErrors(errors))
  })
}
