import { createAction } from 'redux-actions'
import processError from '../../helpers/processError'

import { apiRequest } from 'helpers/Request'

import * as appActions from './app'
import { fileActions } from '../types'

export const fileAdd = createAction(fileActions.FILE_ADD)
export const initializeDocuments = createAction(fileActions.INITIALIZE_DOCUMENTS)

export function fileUpload(evault_id, data) {
  return (dispatch) => {
    dispatch(appActions.appLoading(true))
    apiRequest({
      endpoint: `/evault/${evault_id}`,
      method: 'post',
      data,
    })
      .then((response) => {
        const newDocument = { document_name: data.get('document').name }
        dispatch(fileAdd(newDocument))
        dispatch(appActions.appLoading(false))
        dispatch(appActions.appSuccesses(['Your file has been uploaded.']))
      })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
        dispatch(appActions.appLoading(true))
      })
  }
}
