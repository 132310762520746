import { handleActions } from 'redux-actions'

import { orderActions } from '../types'

const initialState = {
  id: null,
  payment_method: null,
  stripe_token: null,
  payments_enabled: null,
}

export default handleActions(
  {
    [orderActions.ORDER_SET]: (state, action) => ({
      ...action.payload.data,
    }),
    [orderActions.ORDER_UPDATE]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState,
)
