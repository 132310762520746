import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

function apiRequest({ endpoint, method, data }) {
  const url = `${API_URL}${endpoint}`
  return axios[method](url, data)
}

export const apiToReadable = str => str.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')

export { apiRequest }
