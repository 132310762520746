export const userActions = {
  USER_SET: 'USER_SET',
  IS_LENDER: 'LENDER',
  USER_UPDATE: 'USER_UPDATE',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_PASSWORD_RESET: 'USER_PASSWORD_RESET',
  USER_REQUEST_PASSWORD_RESET: 'USER_REQUEST_PASSWORD_RESET',
}

export const appActions = {
  APP_LOADING: 'APP_LOADING',
  APP_COMPONENT: 'APP_COMPONENT',
  APP_MODAL: 'APP_MODAL',
  APP_ERRORS: 'APP_ERRORS',
  APP_SUCCESSES: 'APP_SUCCESSES',
  APP_FORM_ERRORS: 'APP_FORM_ERRORS',
  APP_STEP: 'APP_STEP',
  APP_STEP_INCREMENT: 'APP_STEP_INCREMENT',
  APP_VALIDATE: 'APP_VALIDATE',
  APP_IFRAME: 'APP_IFRAME',
  SET_TOKEN: 'SET_TOKEN',
}

export const scheduleActions = {
  SCHEDULE_SET: 'SCHEDULE_SET',
}

export const fileActions = {
  FILE_ADD: 'FILE_ADD',
  INITIALIZE_DOCUMENTS: 'INITIALIZE_DOCUMENTS',
}

export const orderActions = {
  ORDER_SET: 'ORDER_SET',
  ORDER_UPDATE: 'ORDER_UPDATE',
}

export const notificationActions = {
  NOTIFICATION_ERRORS: 'NOTIFICATION_ERRORS',
}

export const creditCardActions = {
  CREDITCARD_SET: 'CREDITCARD_SET',
  CREDITCARD_UPDATE: 'CREDITCARD_UPDATE',
}

export const balanceActions = {
  BALANCE_SET: 'BALANCE_SET',
}

export const propertyDetailActions = {
  SET_DETAILS: 'SET_DETAILS',
  SET_DETAIL_VALUE: 'SET_DETAIL_VALUE',
  LOAD_DETAILS: 'LOAD_DETAILS',
}
