const Routes = {
  schedule: '/schedule/*',
  login: '/login',
  register: '/register',
  password_reset: '/reset_password/:token',
  error: '/error',
  info: '/info',
  consent: '/consent/:token',
}

export default Routes
