import React from 'react'
import { Steps } from 'antd'
const { Step } = Steps;

const DesktopStepper = (props) => {
  const {
    step,
    steps
  } = props

  return (
    <div className="header-steps">
      <Steps current={step}>
        {steps.map(stepTitle => <Step key={stepTitle} title={stepTitle} />)}
      </Steps>
    </div>
  )
}

export default DesktopStepper
