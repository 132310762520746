import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as appActions from 'store/actions/app';
import * as userActions from 'store/actions/user';

import Input from 'components/Input';
import Validator from 'helpers/Validator';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: '',
      confirm_password: '',
    };
  }

  onFieldUpdate = (value, field) => {
    this.setState({ [field]: value });
  };

  resetPassword = () => {
    const { new_password, confirm_password } = this.state;
    const { appValidate } = this.props.appActions;
    const { userPasswordReset } = this.props.userActions;
    const validation = {
      new_password: [Validator.required(new_password), Validator.checkLength(new_password, 8)],
      confirm_password: [
        Validator.required(confirm_password),
        Validator.checkLength(confirm_password, 8),
        Validator.sameInput(new_password, confirm_password),
      ],
    };
    const data = {
      password: this.state.new_password,
      token: this.props.match.params.token,
    };
    appValidate(validation, userPasswordReset, data);
  };

  render() {
    const { formErrors } = this.props.app;
    return (
      <div className="container small">
        <div className="center-all">
          <div className="box bg">
            <h1>Password Reset</h1>
            <p className="info">Please enter new password below</p>
            <Input
              input={{ type: 'password', placeholder: 'Enter New Password' }}
              onChange={this.onFieldUpdate}
              field="new_password"
              value={this.state.new_password}
              errors={formErrors.new_password}
              icon="lock_outline"
            />
            <Input
              input={{ type: 'password', placeholder: 'Confirm Password' }}
              onChange={this.onFieldUpdate}
              onSubmit={this.resetPassword}
              field="confirm_password"
              value={this.state.confirm_password}
              errors={formErrors.confirm_password}
              icon="lock_outline"
            />
            <div className="button" onClick={this.resetPassword}>
              Update Password
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => ({ app });
const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators(appActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
