import React, { Component } from 'react'

import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import './InputTime.css'
import Input from 'components/Input'


class InputTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hour: '',
      minute: '',
      ampm: 'am',
    }
  }

  handleHours = (hour) => {
    const { onChange, fieldID, time } = this.props
    const timeOrg = time.clone()
    if (timeOrg.format('a') === 'pm') {
      time.hours(hour.value).add(12, 'hours')
    } else {
      time.hours(hour.value)
    }

    onChange(time, fieldID)
  }

  handleMinutes = (minute) => {
    const { onChange, fieldID, time } = this.props
    if (minute >= 0 && minute < 60) {
      time.minutes(minute)
    } else if (minute < 0) {
      time.minute(55)
    } else {
      time.minutes(0)
    }
    onChange(time, fieldID)
  }

  handleAmPm = (value) => {
    const { onChange, fieldID, time } = this.props
    if (value === 'am') {
      this.setState({ ampm: 'am' })
      time.subtract(12, 'hours')
    } else {
      this.setState({ ampm: 'pm' })
      time.add(12, 'hours')
    }
    onChange(time, fieldID)
  }

  getTime = () => {
    const selectedHour = this.props.time.format('H')

    if (selectedHour > 12) {
      return this.props.time.format('H') - 12
    } else {
      return this.props.time.format('H')
    }
  }

  hourOptions = () => {
    const defaultTime = 13
    const options = [...Array(defaultTime).keys()]
    options.shift()
    return options
  }

  render() {
    return (
      <div className="time-input">
        <Dropdown className={'hour-dropdown'} options={this.hourOptions()} onChange={this.handleHours} placeholder={this.getTime()}/>
        <span>:</span>
        <Input
          onChange={this.handleMinutes}
          value={this.props.time.format('mm')}
          input={{
            type: 'number',
            placeholder: 'MM',
            step: '5',
          }}
        />
        <div className="am-pm">
          <p
            className={this.props.time.format('a') === 'am' ? 'active' : ''}
            onClick={() => this.handleAmPm('am')}
          >
            AM
          </p>
          <p
            className={this.props.time.format('a') === 'pm' ? 'active' : ''}
            onClick={() => this.handleAmPm('pm')}
          >
            PM
          </p>
        </div>
      </div>
    )
  }
}

export default InputTime
