import { handleActions } from 'redux-actions'

import { appActions } from '../types'

const initialState = {
  loading: false,
  component: '',
  step: 0,
  errors: [], // right now just list of strings, in future list of objects with key as info level
  successes: [],
  formErrors: {}, // { password: ['Field required', 'Min 8 characters']}
  modal: '',
  iframe: false,
  token: undefined,
}

export default handleActions(
  {
    [appActions.APP_STEP]: (state, action) => ({
      ...state,
      step: action.payload,
    }),
    [appActions.APP_STEP_INCREMENT]: state => ({
      ...state,
      step: state.step + 1,
    }),
    [appActions.APP_LOADING]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [appActions.APP_FORM_ERRORS]: (state, action) => ({
      ...state,
      formErrors: action.payload,
    }),
    [appActions.APP_ERRORS]: (state, action) => ({
      ...state,
      errors: action.payload,
    }),
    [appActions.APP_SUCCESSES]: (state, action) => ({
      ...state,
      successes: action.payload,
    }),
    [appActions.APP_COMPONENT]: (state, action) => ({
      ...state,
      component: action.payload,
    }),
    [appActions.APP_MODAL]: (state, action) => ({
      ...state,
      modal: action.payload,
    }),
    [appActions.APP_IFRAME]: (state, action) => ({
      ...state,
      iframe: action.payload,
    }),
    [appActions.SET_TOKEN]: (state, action) => ({
      ...state,
      token: action.payload,
    }),
  },
  initialState,
)
