import { createAction } from 'redux-actions'

import { apiRequest } from 'helpers/Request'
import processError from '../../helpers/processError'

import * as appActions from './app'
import {
  userSet,
  isLender,
} from './user'
import {
  initializeDocuments,
} from './files'
import {
  creditCardSet,
} from './creditcard'
import {
  orderSet,
} from './order'

import { scheduleActions, balanceActions } from '../types'

export const scheduleSet = createAction(scheduleActions.SCHEDULE_SET)
export const balanceSet = createAction(balanceActions.BALANCE_SET)

export function scheduleTokenGet(token) {
  return dispatch => {
    dispatch(appActions.appLoading(true))
    apiRequest({
      endpoint: `/schedule/token/${token}`,
      method: 'get',
    }).then(response => {
      dispatch(scheduleSet(response.data.schedule))
      dispatch(userSet({ data: response.data.user }))
      dispatch(isLender({ lender: response.data.lender }))
      dispatch(balanceSet({balance: response.data.outstanding_balance}))
      dispatch(creditCardSet(response.data.info))
      //      dispatch(initializeDocuments(response.data.evault.documents));
      dispatch(orderSet({ data: response.data.order }))
      dispatch(appActions.appComponent('steps'))
      dispatch(appActions.appLoading(false))
    })
  }
}

export function scheduleGet(schedule_id) {
  return (dispatch) => {
    dispatch(appActions.appLoading(true))
    apiRequest({
      endpoint: '/schedule',
      method: 'get',
      data: {
        params: {
          id: schedule_id,
        },
      },
    })
      .then((response) => {
        dispatch(scheduleSet(response.data))
        dispatch(appActions.appComponent('steps'))
        dispatch(appActions.appLoading(false))
      })
      .catch((error) => {
        const e = processError(error)
        dispatch(appActions.appErrors(e))
      })
  }
}

export function schedulePayment(schedule_id, data) {
  return (dispatch) => {
    dispatch(appActions.appLoading(true))
    apiRequest({
      endpoint: `/schedule/${schedule_id}`,
      method: 'put',
      data,
    })
      .then((response) => {
        Promise.resolve(dispatch(scheduleSet(response.data))).then(
          dispatch(appActions.appStepIncrement()),
          dispatch(appActions.appLoading(false)),
        )
      })
      .catch((error) => {
        const e = processError(error)
        Promise.resolve(dispatch(appActions.appErrors(e))).then(appActions.appLoading(false))
      })
  }
}

export function schedulePut(data) {
  return (dispatch) => {
    dispatch(appActions.appLoading(true))
    apiRequest({
      endpoint: '/schedule/consumerTimes',
      method: 'put',
      data,
    })
      .then((response) => {
        Promise.resolve(dispatch(scheduleSet(response.data.schedule))).then(
          dispatch(appActions.appStepIncrement()),
          dispatch(appActions.appLoading(false)),
        )
      })
      .catch((error) => {
        const e = processError(error)
        Promise.resolve(dispatch(appActions.appErrors(e))).then(appActions.appLoading(false))
      })
  }
}

export function scheduleDocuments() {}

export function scheduleUploadDocument() {}
