import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import app from './app'
import files from './files'
import schedule from './schedule'
import user from './user'
import order from './order'
import balance from './balance'
import credit_card from './creditcard'
import propertyDetails from './propertyDetails'

export const rootReducer = combineReducers({
  app,
  schedule,
  user,
  files,
  order,
  credit_card,
  balance,
  propertyDetails,
  routing: routerReducer,
})
