import { handleActions } from 'redux-actions'

import { balanceActions } from '../types'

const initialState = {
    amount: 0
  }

  export default handleActions(
    {
      [balanceActions.BALANCE_SET]: (state, action) => {
        return ({
            ...state,
            amount: action.payload.balance,
          })
      },
    },
    initialState,
  )